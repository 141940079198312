import { DEFAULT_LABEL } from '../constants'
import { v4 as uuidv4 } from 'uuid'
import objectExtensions from './object'

export const getProductByCurrentJob = (currentJob) => {
  if (!currentJob) return null
  return currentJob.product
}

export const getPropertiesDefault = (product) => {
  if (!product) return []
  if (!product.properties || product.properties.length === 0) return []
  return product.properties.filter((item) => item.isShowDefault)
}

export const getLabelProperties = (
  properties,
  defaultLabel = DEFAULT_LABEL
) => {
  if (!properties) return defaultLabel
  return properties.description
    ? properties.description
    : properties.shortDisplayName
    ? properties.shortDisplayName
    : defaultLabel
}

export const mapObjectVariables = (variables) => {
  const mapVariables = {
    data: [],
    minValue: 0,
    maxValue: 0
  }
  if (!variables) return mapVariables
  var currentVariables = variables.split(',')
  if (!currentVariables || currentVariables.length === 0) return mapVariables
  mapVariables.minValue = currentVariables[0]
  mapVariables.maxValue = currentVariables[currentVariables.length - 1]
  mapVariables.data = objectExtensions.initOptions(currentVariables)
  return mapVariables
}

export const initProperties = (properties) => {
  if (!properties || properties.length <= 0) return []
  return properties.map((item, index) => {
    const initVariables = mapObjectVariables(item.variables)
    return {
      ...item,
      variables: initVariables.data,
      minValue: initVariables.minValue,
      maxValue: initVariables.maxValue
    }
  })
}

export const initCurrentJob = (product) => {
  if (!product) return null
  return {
    id: uuidv4(),
    productId: product.id,
    name: product.name,
    images: product.images,
    properties: initProperties(product.properties)
  }
}
