import { createSelector } from 'reselect'
import { USER_SESSION_PATH } from '../constants/path'
import { initUserSessionStyleItem } from '../extensions/userSession'

const getData = (state) => (state.data ? state.data : null)

const getCurrentSession = (state) =>
  state.userSession ? state.userSession : null

const getUserSessions = (data) => {
  if (!data) return []
  return data.mySessions ? data.mySessions || [] : []
}

export const selectUserSessions = () =>
  createSelector([getData], (data) => {
    return getUserSessions(data ? data.get(USER_SESSION_PATH) : null)
  })

export const selectCurrentSession = () =>
  createSelector([getCurrentSession], (data) => {
    return data || null
  })

export const selectUserInfo = () =>
  createSelector([getCurrentSession], (data) => {
    return data?.userInfo
  })

export const selectUserSessionStyles = () =>
  createSelector([getCurrentSession], (data) => {
    return data?.userSessionStyles || []
  })

export const countSessions = () =>
  createSelector([getData], (data) => {
    return (
      getUserSessions(data ? data.get(USER_SESSION_PATH) : null)?.length ?? 0
    )
  })

export const selectActiveUserSessionStyle = () =>
  createSelector([getCurrentSession], (data) => {
    return {
      activeStyle: data?.activeStyle || null,
      isCollageStyle: data?.isCollageStyle || false,
      sessionId: data?.id ?? null
    }
  })

export const selectActiveItemStyle = (item) =>
  createSelector([getCurrentSession], (data) => {
    let activeStyle = initUserSessionStyleItem(item)
    if (data) {
      const userSessionStyles = data.userSessionStyles || []

      if (userSessionStyles?.length > 0) {
        const findItem = userSessionStyles.find(
          (s) => s.featureCategoryId === item.featureCategoryId
        )
        if (findItem) {
          activeStyle = findItem
        }
      }
    }

    return activeStyle
  })

export const selectActiveCategory = () =>
  createSelector([getCurrentSession], (data) => data?.activeCategory ?? null)

export const selectAllSummarySession = (listFeatureCategory = []) =>
  createSelector([getCurrentSession], (data) => {
    let sessions = []

    if (data?.userSessionStyles?.length > 0) {
      data.userSessionStyles.forEach((style) => {
        const { featureCategoryId } = style
        const fc = listFeatureCategory.find(
          ({ featureCategory }) => featureCategory.id === featureCategoryId
        )
        if (style?.tabStyles?.length > 0) {
          style.tabStyles.forEach((tab) => {
            if (tab.styleItem && Object.keys(tab.styleItem).length > 0) {
              sessions.push({
                style: tab.styleItem,
                name: fc?.featureCategory?.name,
                fcId: fc?.featureCategory?.id
              })
            } else {
              sessions.push({
                style: null,
                name: fc?.featureCategory?.name,
                fcId: fc?.featureCategory?.id
              })
            }
          })
        }
      })
    }

    const missingFc = listFeatureCategory.filter(({ featureCategory }) =>
      sessions.every(({ fcId }) => fcId !== featureCategory.id)
    )

    if (missingFc.length > 0) {
      missingFc.forEach((fc) => {
        sessions.push({
          style: fc.defaultStyle,
          name: fc.featureCategory.name,
          id: fc.featureCategory.id
        })
      })
    }

    return sessions
  })
