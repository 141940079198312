import ActionTypes from '../actions/types'

const initialState = {
  fpLoading: false
}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.START_FETCH_FEATURE_PRODUCT:
      return Object.assign({}, state, {
        fpLoading: true
      })
    case ActionTypes.STOP_FETCH_FEATURE_PRODUCT:
      return Object.assign({}, state, {
        fpLoading: false
      })

    default:
      return state
  }
}

export default CommonReducer
