export const HEADER_MENU = [
  {
    title: 'List styles'
  },
  {
    title: 'Selected'
  },
  {
    title: 'Customize'
  }
]