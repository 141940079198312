// lib
import ActionTypes from './types'
import { routeAuth, routes } from '../routes/home'
import { loadSuccess, redirectPath } from './commonActions'
import axiosProvider from './api/axiosProvider'
import {
  COOKIE_AUTH,
  COOKIE_REFRESH_AUTH,
  COOKIE_VERIFICATION_CODE,
  GRAPHQL_PATH,
  MODAL_AUTH,
  MODAL_MESSAGE
} from '../constants'
import { CHANGE_PASSWORD_SUCCESS } from '../constants/message'
import { setErrorFromApi, showSuccessNotification } from './formActions'
import authQuery from './queries/auth'
import { verifyToken } from '../extensions/jwt'
import { getCookie, removeCookie, setCookie } from '../extensions/cookie'
import modalActions from './modalActions'
import { querySendPasswordCode, queryUpdateProfile } from '../clauseSearch/auth'
import { stringExtensions } from '../extensions'
import dataActions from './dataActions'
import {
  FEATURE_CATEGORY_PATH,
  FEATURE_PRODUCT_PATH,
  USER_SESSION_PATH
} from '../constants/path'
import userSessionActions from './userSessionActions'

export default {
  logout() {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.USER_LOGGED_OUT
      })
      dispatch(userSessionActions.resetSession())

      dispatch(dataActions.resetData('mySessions', [], USER_SESSION_PATH))

      removeCookie(COOKIE_AUTH)
      removeCookie(COOKIE_REFRESH_AUTH)
      redirectPath(routes.ROUTE_HOME_PAGE)
    }
  },

  login(queryClause, pathRedirect) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.login(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.login ?? null
          const token = verifyToken(result.token)
          if (token) {
            setCookie({
              name: COOKIE_AUTH,
              value: result.token,
              expires: new Date(result.expiresIn)
            })
            setCookie({
              name: COOKIE_REFRESH_AUTH,
              value: result.refreshToken,
              expires: new Date(result.refreshExpiresIn)
            })

            const user = token?.user

            dispatch(
              loadSuccess({
                actionType: ActionTypes.USER_LOGGED_IN,
                data: {
                  user: user,
                  expiresIn: result.expiresIn
                },
                pathRedirect: pathRedirect,
                callback: [
                  modalActions.hiddenModal(MODAL_AUTH),
                  dataActions.loadDataPager(null, USER_SESSION_PATH)
                ]
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  register(queryClause, pathRedirect) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.register(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.register ?? null
          const token = verifyToken(result.token)
          if (token) {
            setCookie({
              name: COOKIE_AUTH,
              value: result.token,
              expires: new Date(result.expiresIn)
            })
            setCookie({
              name: COOKIE_REFRESH_AUTH,
              value: result.refreshToken,
              expires: new Date(result.refreshExpiresIn)
            })

            const user = token?.user

            dispatch(
              loadSuccess({
                actionType: ActionTypes.USER_LOGGED_IN,
                data: {
                  user: user,
                  expiresIn: result.expiresIn
                },
                pathRedirect: pathRedirect,
                callback: [modalActions.hiddenModal(MODAL_AUTH)]
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  verificationCode(queryClause, pathRedirect) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.verification(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.verification ?? null

          console.log('result..........', result)

          if (result) {
            setCookie({
              name: COOKIE_VERIFICATION_CODE,
              value: result
            })

            dispatch(
              loadSuccess({
                pathRedirect: pathRedirect
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  refreshToken(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.refreshToken(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.refreshToken ?? null
          const token = verifyToken(result.token)
          if (token) {
            setCookie({
              name: COOKIE_AUTH,
              value: result.token,
              expires: new Date(result.expiresIn)
            })
            setCookie({
              name: COOKIE_REFRESH_AUTH,
              value: result.refreshToken,
              expires: new Date(result.refreshExpiresIn)
            })
            dispatch(
              loadSuccess({
                actionType: ActionTypes.REFRESH_TOKEN,
                data: result.expiresIn
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  changePassword(queryClause, pathRedirect) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.changePassword(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.changePassword
          dispatch(
            loadSuccess({
              actionType: ActionTypes.CHANGE_PASSWORD_SUCCESS,
              data: result,
              pathRedirect: pathRedirect,
              callback: result
                ? [
                    modalActions.openModal(MODAL_MESSAGE, {
                      message: 'Change Password successfully!'
                    })
                  ]
                : []
            })
          )
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  resetChangePassword() {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS
      })
    }
  },

  updateProfile(data, isRefresh) {
    return async (dispatch) => {
      try {
        const queryClause = queryUpdateProfile(data)
        const queryData = authQuery.updateProfile(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.updateProfile
          if (result) {
            const refreshCookie = getCookie(COOKIE_REFRESH_AUTH)
            const refreshToken = stringExtensions.removeEscapeCharacter(
              refreshCookie
            )
            const query = `token: "${refreshToken}"`
            dispatch(
              loadSuccess({
                actionType: ActionTypes.UPDATE_PROFILE_SUCCESS,
                data,
                callback: isRefresh ? [this.refreshToken(query)] : []
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  restorePassword(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.restorePassword(queryClause)

        const result = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })
        if (result.status === 200) {
          if (
            result.data.data &&
            (!result.data.errors || result.data.errors.length === 0)
          ) {
            dispatch(
              loadSuccess({
                actionType: ActionTypes.RESTORE_PASSWORD_SUCCESS,
                pathRedirect: routeAuth.LOGIN
              })
            )
          } else {
            dispatch(setErrorFromApi(result.data.errors[0]))
          }
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  startSendPasswordCode(email) {
    return (dispatch) =>
      dispatch({
        type: ActionTypes.START_SEND_PASSWORD_CODE,
        payload: email
      })
  },

  sendPasswordCode(email) {
    return async (dispatch) => {
      try {
        const queryClause = querySendPasswordCode(email)

        const queryData = authQuery.sendCode(queryClause)

        const result = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })
        if (result.status === 200) {
          if (
            result.data.data &&
            (!result.data.errors || result.data.errors.length === 0)
          ) {
            dispatch(this.startSendPasswordCode(email))
          } else {
            dispatch(setErrorFromApi(result.data.errors[0]))
          }
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  }
}
