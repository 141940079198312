import React, { lazy } from 'react'

export const routes = {
  ROUTE_HOME_PAGE: '/',
  ROUTE_USER_SESSION: '/session',
  ROUTER_AUTH: `/auth`,
  ROUTE_PROFILE: '/profile'
}

export const routeAuth = {
  LOGIN: `${routes.ROUTER_AUTH}/login`,
  REGISTER: `${routes.ROUTER_AUTH}/register`,
  LANDING_PAGE: `${routes.ROUTER_AUTH}/landing-page`,
  FORGOT_PASSWORD: `${routes.ROUTER_AUTH}/forgot-password`
}

export const resources = {
  LOGIN: {
    title: 'Login',
    key: 'LOGIN'
  },
  REGISTER: {
    title: 'Register',
    key: 'REGISTER'
  },
  LANDING_PAGE: {
    title: 'Verification Page',
    key: 'VERIFICATION_PAGE'
  },
  FORGOT_PASSWORD: {
    title: 'Forgot password',
    key: 'FORGOT_PASSWORD'
  }
}

export const navHome = [
  {
    name: resources.LOGIN.title,
    key: resources.LOGIN.key,
    resource: resources.LOGIN.key,
    path: routeAuth.LOGIN,
    component: lazy(() => import('../view/Authentication/Login')),
    isMenu: false,
    isProtected: false
  },
  {
    name: resources.REGISTER.title,
    key: resources.REGISTER.key,
    resource: resources.REGISTER.key,
    path: routeAuth.REGISTER,
    component: lazy(() => import('../view/Authentication/Register')),
    isMenu: false,
    isProtected: false
  },
  {
    name: resources.FORGOT_PASSWORD.title,
    key: resources.FORGOT_PASSWORD.key,
    resource: resources.FORGOT_PASSWORD.key,
    path: routeAuth.FORGOT_PASSWORD,
    component: lazy(() => import('../view/Authentication/ForgotPassword')),
    isMenu: false,
    isProtected: false
  },
  {
    title: 'User Jobs',
    path: `${routes.ROUTE_USER_SESSION}/:id`,
    component: lazy(() => import('../view/HomePage')),
    isProtected: true,
    isExactly: true
  },
  {
    title: 'User Jobs',
    path: routes.ROUTE_PROFILE,
    component: lazy(() => import('../view/Profile')),
    isProtected: true,
    isExactly: true
  },
  {
    title: 'Home',
    path: `${routes.ROUTE_HOME_PAGE}`,
    component: lazy(() => import('../view/HomePage')),
    isProtected: true,
    isExactly: true
  }
]

export const navAuth = [
  {
    name: resources.LANDING_PAGE.title,
    key: resources.LANDING_PAGE.key,
    resource: resources.LANDING_PAGE.key,
    path: routeAuth.LANDING_PAGE,
    component: lazy(() => import('../view/Authentication/LandingPage')),
    isMenu: false,
    isProtected: false
  }
]
