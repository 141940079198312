import React from 'react'
// lib
import { Redirect, Route, withRouter } from 'react-router-dom'
// component
import AccessDenied from '../AccessDenied'
// routes
import { routeAuth, routes } from '../../routes/home'
import { getCookie } from '../../extensions/cookie'
import {
  APPLY_VERIFICATION_CODE,
  COOKIE_AUTH,
  COOKIE_VERIFICATION_CODE
} from '../../constants'

const ProtectedRoute = ({
  component: Component,
  resource,
  action,
  auth,
  config,
  isMobile,
  ...rest
}) => {

  const permission = APPLY_VERIFICATION_CODE
    ? getCookie(COOKIE_VERIFICATION_CODE)
    : getCookie(COOKIE_AUTH)

  if (permission) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Component {...matchProps} auth={auth} isMobile={isMobile} />
        )}
      />
    )
  }
  console.log('must redirect...')
  return (
    <Redirect to={`${APPLY_VERIFICATION_CODE ? routeAuth.LANDING_PAGE : routeAuth.LOGIN}?redirectURL=${rest.location.pathname}`} />
  )
}

export default withRouter(ProtectedRoute)
