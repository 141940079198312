import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
// lib
import { withFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import ErrorMessage from '../../../components/Form/ErrorMessage'
// extensions
import { formikExtensions, yupExtensions } from '../../../extensions'
import {
  BUTTON_REGISTER,
  LABEL_EMAIL,
  LABEL_PASSWORD,
  LABEL_FIRST_NAME,
  LABEL_LAST_NAME,
  LABEL_ADDRESS,
  LABEL_PHONE_NUMBER,
  LABEL_CONFIRM_PASSWORD
} from '../../../constants/label'
import { COL_12, COL_24, DEFAULT_GUTTER } from '../../../constants/layout'
import {
  selectApiErrorCode,
  selectApiErrorMessage
} from '../../../reselect/apiError'
import { AUTHENTICATE_FAIL } from '../../../constants/error'
import validate from '../../../constants/validate'
import SuggestLogin from '../../../components/Auth/SuggestLogin'

const FormItem = Form.Item

const COL_HALF_LAYOUT = {
  span: COL_24,
  lg: COL_12
}

const formikMap = withFormik({
  validationSchema: Yup.object().shape({
    firstName: yupExtensions.stringRequired,
    lastName: yupExtensions.stringRequired,
    email: yupExtensions.emailRequired,
    password: yupExtensions.stringRequired,
    confirmPass: yupExtensions.stringRequired.oneOf(
      [Yup.ref('password')],
      validate.PASSWORD_NOT_MATCH
    )
  }),
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    phoneNumber: '',
    confirmPass: '',
    error: null
  }),
  handleSubmit: (data, { props }) => {
    props.handleSubmitForm(data)
  },
  displayName: 'Form'
})

const LoginForm = (props) => {
  const {
    isPopup,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError
  } = props

  const apiError = useSelector(selectApiErrorCode())
  const apiErrorMessage = useSelector(selectApiErrorMessage())

  useEffect(() => {
    if (apiError === AUTHENTICATE_FAIL.code) {
      setFieldError('email', ' ')
      setFieldError('password', AUTHENTICATE_FAIL.message)
    } else {
      setFieldError('error', apiErrorMessage)
    }
  }, [apiError, apiErrorMessage])

  return (
    <Form
      // {...SMALL_LABEL_LAYOUT}
      layout='vertical'
      onFinish={handleSubmit}
      className='login-form'
    >
      <Row gutter={DEFAULT_GUTTER}>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem label={LABEL_FIRST_NAME} className='item-login' required>
            <Input
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'firstName'
                })
              })}
              placeholder={LABEL_FIRST_NAME}
              value={values.firstName}
              onChange={(input) =>
                setFieldValue('firstName', input.target.value)
              }
              onBlur={() => setFieldTouched('firstName', true)}
            />
            <ErrorMessage fieldName={'firstName'} />
          </FormItem>
        </Col>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem label={LABEL_LAST_NAME} className='item-login' required>
            <Input
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'lastName'
                })
              })}
              placeholder={LABEL_LAST_NAME}
              value={values.lastName}
              onChange={(input) =>
                setFieldValue('lastName', input.target.value)
              }
              onBlur={() => setFieldTouched('lastName', true)}
            />
            <ErrorMessage fieldName={'lastName'} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={DEFAULT_GUTTER}>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem label={LABEL_ADDRESS} className='item-login'>
            <Input.TextArea
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'address'
                })
              })}
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder={LABEL_ADDRESS}
              value={values.address}
              onChange={(input) => setFieldValue('address', input.target.value)}
              onBlur={() => setFieldTouched('address', true)}
            />
            <ErrorMessage fieldName={'address'} />
          </FormItem>
        </Col>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem label={LABEL_PHONE_NUMBER} className='item-login'>
            <Input
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'phoneNumber'
                })
              })}
              placeholder={LABEL_PHONE_NUMBER}
              value={values.phoneNumber}
              onChange={(input) =>
                setFieldValue('phoneNumber', input.target.value)
              }
              onBlur={() => setFieldTouched('phoneNumber', true)}
            />
            <ErrorMessage fieldName={'phoneNumber'} />
          </FormItem>
        </Col>
      </Row>

      <FormItem label={LABEL_EMAIL} className='item-login' required>
        <Input
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              touched,
              fieldName: 'email'
            })
          })}
          placeholder={LABEL_EMAIL}
          value={values.email}
          onChange={(input) => setFieldValue('email', input.target.value)}
          onBlur={() => setFieldTouched('email', true)}
        />
        <ErrorMessage fieldName={'email'} />
      </FormItem>

      <Row gutter={DEFAULT_GUTTER}>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem label={LABEL_PASSWORD} className='item-login' required>
            <Input.Password
              visibilityToggle={true}
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'password'
                })
              })}
              placeholder={LABEL_PASSWORD}
              value={values.password}
              onChange={(input) =>
                setFieldValue('password', input.target.value)
              }
              onBlur={() => setFieldTouched('password', true)}
            />
            <ErrorMessage fieldName={'password'} />
          </FormItem>
        </Col>
        <Col {...COL_HALF_LAYOUT}>
          <FormItem
            label={LABEL_CONFIRM_PASSWORD}
            className='item-login'
            required
          >
            <Input.Password
              visibilityToggle={true}
              className={classNames({
                'has-error': formikExtensions.checkFieldError({
                  errors,
                  touched,
                  fieldName: 'confirmPass'
                })
              })}
              placeholder={LABEL_CONFIRM_PASSWORD}
              value={values.confirmPass}
              onChange={(input) =>
                setFieldValue('confirmPass', input.target.value)
              }
              onBlur={() => setFieldTouched('confirmPass', true)}
            />
            <ErrorMessage fieldName={'confirmPass'} />
          </FormItem>
        </Col>
      </Row>

      <ErrorMessage fieldName={'error'} customClass={'mb-3'} />

      <Row gutter={DEFAULT_GUTTER}>
        <Col span={COL_24}>
          <div className='form-actions text-center mb-3 mb-lg-0'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ant-btn w__150'
            >
              {BUTTON_REGISTER}
            </Button>
          </div>
        </Col>
        <Col span={COL_24}>
          <SuggestLogin isPopup={!!isPopup} />
        </Col>
      </Row>
    </Form>
  )
}

export default formikMap(LoginForm)
