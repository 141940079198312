import {reducer as notifications} from 'react-notification-system-redux'
import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {reducer as modal} from 'redux-modal'
import data from './data'
import userSession from './userSession'
import auth from './auth'
import apiError from './apiError'
import common from './common'
import tabs from './tabs'
import category from './category'
import feature from './feature'
import readyStyle from './readyStyle'

export const deepCopyState = (value) => JSON.parse(JSON.stringify(value))

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    data,
    userSession,
    modal,
    auth,
    apiError,
    common,
    tabs,
    category,
    feature,
    readyStyle
  })

export default rootReducer
