import moment from 'moment'

export const FORMAT_DATE = 'YYYY-MM-DD'

export const FORMAT_DATE_TIME='YYYY-MM-DD HH:mm'

export const FORMAT_DATE_TIME_STRING='YYYYMMDDHHmm'

export const getNewDate = (time) => (time ? new Date(time) : new Date())

export const isPreviousDate = (dateCheck, dateCompare) => {
  if (!dateCheck || !dateCompare) {
    return false
  }

  return new Date(dateCheck) < new Date(dateCompare)
}

export const isFutureDate = (dateCheck, dateCompare) => {
  if (!dateCheck || !dateCompare) {
    return false
  }

  return new Date(dateCheck) > new Date(dateCompare)
}

export const formatDateTimeToString = (dateTimeMoment, formatString = FORMAT_DATE) => {
  if(!dateTimeMoment) return null
  return formatMomentDateTimeToString(moment(dateTimeMoment), formatString);
}

export const formatMomentDateTimeToString = (dateTimeMoment, formatString = FORMAT_DATE) => {
  if(!dateTimeMoment) return null
  return dateTimeMoment.format(formatString);
}
