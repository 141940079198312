export const CATEGORY_PATH = 'category'

export const SELECT_CATEGORY_PATH = 'selectCategory'

export const PRODUCT_PATH = 'product'

export const PROPERTY_PATH = 'property'

export const FEATURE_PATH = 'feature'

export const FEATURE_CATEGORY_PATH = 'featureCategory'

export const FEATURE_PRODUCT_PATH = 'featureProduct'

export const FINISH_LIST_PATH = 'finishList'

export const USER_SESSION_PATH = 'userSession'

export const TABS_PATH = 'tabs'

