import {DEFAULT_THUMBNAIL, UPLOAD_PRODUCT, UPLOAD_FEATURE} from "../constants";

const DOMAIN_IMAGE = process.env.REACT_APP_API_BASE_IMAGE_URL || 'http://localhost:4000'

export const generateDomain = () => {
  return DOMAIN_IMAGE.endsWith('/') ? DOMAIN_IMAGE.slice(0, -1) : DOMAIN_IMAGE
}

export const checkHttpUrl = (url) => {
  let pattern = /^((http|https|ftp):\/\/)/
  return pattern.test(url)
}

export const getImageProduct = (product= null, defaultThumbnail = DEFAULT_THUMBNAIL) => {
  if(!product) return defaultThumbnail
  return product.images ? `${generateDomain()}/${UPLOAD_PRODUCT}/${product.images}` : defaultThumbnail
}

export const generatePathWithDomain = (path) => {
  return checkHttpUrl(path) ? path: `${generateDomain()}/${path}`
}

export const generateImages = ({
  object = null,
  defaultThumbnail= DEFAULT_THUMBNAIL,
  dir=UPLOAD_PRODUCT,
  field='images'
}) => {
  if(!object) return defaultThumbnail
  if(!object[field]) return defaultThumbnail
  return checkHttpUrl(object[field]) ? object[field] : `${generateDomain()}/${dir}/${object[field]}`
}
