export default {
  loadDataPager(queryClause) {
    return `
    query {
      featureProducts(${queryClause.whereClause}) {
        id
        name
        description
        images
        isDeleted
        order
        feature{
          id
          type
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      featureProduct(${queryClause}) {
        id
        name
        images
        description
        isDeleted
        order
        feature{
          id
          type
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createFeatureProduct(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateFeatureProduct(${queryClause}) {
        id
      }
    }    
    `
  }
}
