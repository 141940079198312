import React from 'react'

const Logo = () => {
  return (
    <div className='logo float-lg-left text-center text-lg-left'>
      <h1>
        <a
          className='cbn-logo'
          href='/'
        >
          <img
            alt='logo'
            className='img-fluid'
            src={require('../../img/logo.png')}
          />
        </a>

      </h1>
    </div>
  )
}

export default Logo