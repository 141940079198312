export default {
  loadDataPager(queryClause) {
    return `
    query {
      mySessions {
        id
        code
      }
    }    
    `
  },
  loadData(queryClause) {
    return `
    query {
      userSession(${queryClause}) {
        id
        code
        firstName
        lastName
        phoneNumber
        email
        address
        activeStyle
        isCollageStyle
        activeCategory
        userSessionStyles {
          featureCategoryId
          activeTab
          previewStyle
          tabStyles
          currentStyle
        }
        userSessionJobs(order: "order") {
          id
          properties
          product {
            id
            name
            images
          }
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      saveJob(${queryClause}){
        id
        path
      }
    }    
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateUserSession(${queryClause}) {
        id
        code
      }
    }    
    `
  },

  delete(queryClause) {
    return `
    mutation {
      deleteJob(${queryClause})
    }    
    `
  }
}
