import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'antd/lib/button'
import modalActions from '../../actions/modalActions'
import { MODAL_AUTH } from '../../constants'
import { withRouter } from 'react-router-dom'
import { routeAuth } from '../../routes/home'

const SuggestLogin = (props) => {
  const { isPopup = false, history } = props
  const dispatch = useDispatch()

  const openAuthModal = useCallback(
    (options) => dispatch(modalActions.openModal(MODAL_AUTH, options)),
    [dispatch]
  )

  return (
    <div className='text-center mt-2'>
      Already have account?{' '}
      <span>
        <Button
          className='bg-transparent border-0 px-0 text-primary'
          onClick={() =>
            isPopup
              ? openAuthModal({
                  isRegister: false
                })
              : history.push(routeAuth.LOGIN)
          }
        >
          Login
        </Button>
      </span>
    </div>
  )
}
export default withRouter(SuggestLogin)
