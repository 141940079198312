import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Switch, Route } from 'react-router-dom'
import BackTop from 'antd/lib/back-top'
// components
import Layout from './components/Layout/Layout'
import AuthLayout from './components/Layout/AuthLayout'

import { routeAuth, routes } from './routes/home'

import configStore from './configStore'

const { store, history, persistor } = configStore

const App = () => {
  console.log('history........', history.location?.pathname)
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch key={'switch'}>
            <Route path={routeAuth.LANDING_PAGE} component={AuthLayout} />
            <Route path={routes.ROUTE_HOME_PAGE} component={Layout} />
          </Switch>
          <BackTop visibilityHeight={200}>
            <div className='ant-back-top-content'>
              <div className='ant-back-top-icon' />
            </div>
          </BackTop>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
