import React from 'react'
import Layout from 'antd/lib/layout'
import Logo from './Logo'
import Menu from './Menu'
import Profile from './Profile'

const Header = () => {
  return (
    <Layout.Header
      className='cbn-header d-flex justify-content-between'
    >
      <div className='d-flex w-100 justify-content-between justify-content-md-start'>
        <Logo/>
        <Menu/>
      </div>
      <Profile/>
    </Layout.Header>
  )
}

export default Header