import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
// lib
import { withFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import ErrorMessage from '../../../components/Form/ErrorMessage'
// extensions
import { formikExtensions, yupExtensions } from '../../../extensions'
import { GUEST_EMAIL_ACC } from '../../../constants'
import {
  BUTTON_LOGIN,
  LABEL_EMAIL,
  LABEL_PASSWORD
} from '../../../constants/label'
import {
  COL_12,
  COL_24,
  DEFAULT_GUTTER,
  SMALL_LABEL_LAYOUT
} from '../../../constants/layout'
import {
  selectApiErrorCode,
  selectApiErrorMessage
} from '../../../reselect/apiError'
import { AUTHENTICATE_FAIL } from '../../../constants/error'
import SuggestRegister from '../../../components/Auth/SuggestRegister'

const FormItem = Form.Item

const COL_HALF_LAYOUT = {
  span: COL_24,
  lg: COL_12
}

const formikMap = withFormik({
  validationSchema: Yup.object().shape({
    email: yupExtensions.emailRequired,
    password: yupExtensions.stringRequired
  }),
  mapPropsToValues: () => ({
    email: '',
    password: '',
    error: null
  }),
  handleSubmit: (data, { props }) => {
    props.handleSubmitForm(data)
  },
  displayName: 'Form'
})

const LoginForm = (props) => {
  const {
    isPopup,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError
  } = props

  const apiError = useSelector(selectApiErrorCode())
  const apiErrorMessage = useSelector(selectApiErrorMessage())
  const emailRef = useRef(null)
  const passRef = useRef(null)

  useEffect(() => {
    if (apiError === AUTHENTICATE_FAIL.code) {
      setFieldError('email', ' ')
      setFieldError('password', AUTHENTICATE_FAIL.message)
    } else {
      setFieldError('error', apiErrorMessage)
    }
  }, [apiError, apiErrorMessage])

  return (
    <Form
      // {...SMALL_LABEL_LAYOUT}
      layout='vertical'
      onFinish={handleSubmit}
      className='login-form'
    >
      <FormItem label={LABEL_EMAIL} className='item-login'>
        <Input
          ref={emailRef}
          // prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              fieldName: 'email',
              touched
            })
          })}
          placeholder={LABEL_EMAIL}
          value={values.email}
          onChange={(input) => setFieldValue('email', input.target.value)}
          onBlur={() => setFieldTouched('email', true)}
        />
        <ErrorMessage fieldName={'email'} />
      </FormItem>
      <FormItem label={LABEL_PASSWORD} className='item-login'>
        <Input.Password
          itemRef={passRef}
          visibilityToggle={true}
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              touched,
              fieldName: 'password'
            })
          })}
          placeholder={LABEL_PASSWORD}
          value={values.password}
          onChange={(input) => setFieldValue('password', input.target.value)}
          onBlur={() => setFieldTouched('password', true)}
        />
        <ErrorMessage fieldName={'password'} />
      </FormItem>

      <ErrorMessage fieldName={'error'} customClass={'mb-3'} />

      <Row gutter={DEFAULT_GUTTER}>
        <Col span={COL_24}>
          <div className='form-actions text-center mb-3 mb-lg-0'>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ant-btn w__150'
            >
              {BUTTON_LOGIN}
            </Button>
          </div>
        </Col>
        <Col span={COL_24}>
          <SuggestRegister isPopup={!!isPopup} />
        </Col>
      </Row>
    </Form>
  )
}

export default formikMap(LoginForm)
