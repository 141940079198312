import ActionTypes from '../actions/types'
import {
  initUserInfo,
  initUserSessionStyles,
  mapUserSessionWithState,
  replaceContentTab,
  setPreviewUserSessionStyles,
  setValueUserSessionStyles
} from '../extensions/userSession'
import { CABINET_USER } from '../constants'

const initialState = {
  id: null,
  activeCategory: null,
  activeStyle: null,
  isCollageStyle: false,
  userInfo: null,
  userSessionStyles: [],
  currentJob: []
}

const UserSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_USER_SESSION:
      return initialState
    case ActionTypes.FETCH_LOAD_USER_SESSION: {
      const userSession = mapUserSessionWithState(
        action?.payload?.data,
        initialState
      )
      return Object.assign({}, state, {
        ...userSession
      })
    }
    // case ActionTypes.ADD_CURRENT_JOB: {
    //   const stateCurrentJob = state.currentJob || []
    //   const resultData = action.payload.data
    //   if (resultData) {
    //     const exitsItem = stateCurrentJob.find(
    //       (item) => item.id === resultData.id
    //     )
    //     if (!exitsItem) {
    //       stateCurrentJob.push(resultData)
    //     }
    //   }
    //   return Object.assign({}, state, {
    //     currentJob: stateCurrentJob
    //   })
    // }
    // case ActionTypes.REMOVE_CURRENT_JOB: {
    //   const stateCurrentJob = state.currentJob || []
    //   const resultData = action.payload.data
    //   if (resultData) {
    //     const indexItem = stateCurrentJob.findIndex(
    //       (item) => item.id === resultData.id
    //     )
    //     if (indexItem >= 0) {
    //       stateCurrentJob.splice(indexItem, 1)
    //     }
    //   }
    //   return Object.assign({}, state, {
    //     currentJob: stateCurrentJob
    //   })
    // }
    // case ActionTypes.CHANGE_CURRENT_JOB: {
    //   const stateCurrentJob = JSON.parse(JSON.stringify(state.currentJob)) || []
    //   const resultData = action.payload.data
    //   const currentJob = stateCurrentJob.find(
    //     (item) => item.id === resultData.currentJobId
    //   )
    //   if (currentJob) {
    //     const property = currentJob.properties.find(
    //       (item) => item.id === resultData.propertyId
    //     )
    //     if (property) {
    //       property.value = resultData.propertyValue
    //     }
    //   }
    //   return Object.assign({}, state, {
    //     currentJob: stateCurrentJob
    //   })
    // }
    case ActionTypes.SET_USER_SESSION: {
      if (!action.payload.data) return state
      return Object.assign({}, state, {
        ...action.payload.data
      })
    }
    case ActionTypes.SET_ACTIVE_STYLE: {
      if (!action.payload.data) return state
      const { featureCategories, data } = action.payload.data
      const userSessions = initUserSessionStyles(
        state.userSessionStyles,
        action.payload.data
      )
      return Object.assign({}, state, {
        activeStyle: data?.id ?? null,
        isCollageStyle: true,
        userSessionStyles: userSessions
      })
    }
    case ActionTypes.SET_COLLAPSE_STYLE: {
      const currentCollage = state.isCollageStyle
      return Object.assign({}, state, {
        isCollageStyle: !currentCollage
      })
    }
    case ActionTypes.SET_FEATURE_CATEGORY: {
      if (!action.payload.data) return state
      const isSetPreview =
        typeof action.payload.isSetPreview === 'boolean'
          ? action.payload.isSetPreview
          : true
      const userSessions = setPreviewUserSessionStyles(
        state.activeStyle,
        state.userSessionStyles,
        action.payload.data,
        isSetPreview
      )
      return Object.assign({}, state, {
        userSessionStyles: userSessions
      })
    }
    case ActionTypes.ACTIVE_TAB: {
      if (!action.payload.data) return state
      const userSessions = setValueUserSessionStyles(
        state.activeStyle,
        state.userSessionStyles,
        action.payload.data
      )
      return Object.assign({}, state, {
        userSessionStyles: userSessions
      })
    }
    case ActionTypes.REPLACE_CONTENT_TAB: {
      const userSessions = replaceContentTab({
        active: state.activeStyle,
        currentStyles: state.userSessionStyles,
        isReplace: action.payload.isReplace,
        isDelete: action.payload.isDelete,
        featureCategoryId: action.payload.featureCategoryId
      })
      return Object.assign({}, state, {
        userSessionStyles: userSessions
      })
    }
    case ActionTypes.SET_ACTIVE_CATEGORY: {
      return Object.assign({}, state, {
        activeCategory: action.payload.data
      })
    }
    case ActionTypes.SET_USER_INFO: {
      return Object.assign({}, state, {
        userInfo: action.payload.data
      })
    }
    case ActionTypes.UPDATE_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        userInfo: Object.assign({}, state.userInfo, action.payload.data)
      })
    }
    case ActionTypes.CHANGE_USER_INFO: {
      const { data } = action.payload
      if (data?.field) {
        return Object.assign({}, state, {
          userInfo: Object.assign({}, state.userInfo, {
            [data.field]: data?.value ?? null
          })
        })
      }
      return state
    }

    default:
      return state
  }
}

export default UserSessionReducer
