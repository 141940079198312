import React from 'react'
import { connectModal } from 'redux-modal'
import Modal from 'antd/lib/modal'
import { MODAL_MESSAGE } from '../../constants'

const ModalMessage = (props) => {
  const { show, handleHide, message, cancelText, okText } = props

  console.log(show)
  return (
    <Modal
      visible={show}
      footer={false}
      title={false}
      mask={true}
      maskClosable={true}
      closable={true}
      centered={true}
      onCancel={handleHide}
      onOk={handleHide}
      cancelText={cancelText}
      okText={okText}
    >
      {message}
    </Modal>
  )
}

export default connectModal({ name: MODAL_MESSAGE })(ModalMessage)
