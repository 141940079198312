import { stringExtensions } from '../extensions'

export const queryRegister = (values) => {
  let query = ''
  if (values.firstName) {
    const firstName = stringExtensions.removeEscapeCharacter(values.firstName)
    query += `, firstName: "${firstName}"`
  }

  if (values.lastName) {
    const lastName = stringExtensions.removeEscapeCharacter(values.lastName)
    query += `, lastName: "${lastName}"`
  }

  if (values.address) {
    const address = stringExtensions.removeEscapeCharacter(values.address)
    query += `, address: "${address}"`
  }

  if (values.phoneNumber) {
    const phoneNumber = stringExtensions.removeEscapeCharacter(
      values.phoneNumber
    )
    query += `, phoneNumber: "${phoneNumber}"`
  }

  if (values.email) {
    const email = stringExtensions.removeEscapeCharacter(values.email)
    query += `, email: "${email}"`
  }

  if (values.password) {
    const password = stringExtensions.removeEscapeCharacter(values.password)
    query += `, password: "${password}"`
  }

  return `data: {${query}}`
}

export const queryUpdateProfile = (values) => {
  let query = ''
  if (values.firstName) {
    const firstName = stringExtensions.removeEscapeCharacter(values.firstName)
    query += `, firstName: "${firstName}"`
  } else {
    query += `, firstName: null`
  }

  if (values.lastName) {
    const lastName = stringExtensions.removeEscapeCharacter(values.lastName)
    query += `, lastName: "${lastName}"`
  } else {
    query += `, lastName: null`
  }

  if (values.address) {
    const address = stringExtensions.removeEscapeCharacter(values.address)
    query += `, address: "${address}"`
  } else {
    query += `, address: null`
  }

  if (values.phoneNumber) {
    const phoneNumber = stringExtensions.removeEscapeCharacter(
      values.phoneNumber
    )
    query += `, phoneNumber: "${phoneNumber}"`
  } else {
    query += `, phoneNumber: null`
  }

  return `data: {${query}}`
}

export const queryRestorePassword = (data) => {
  let queryClause = ``
  if (data.email) {
    const email = stringExtensions.removeEscapeCharacter(data.email)
    queryClause += `email: "${email}"`
  }
  if (data.code) {
    const code = stringExtensions.removeEscapeCharacter(data.code)
    queryClause += `, verifyCode: "${code}"`
  }
  if (data.newPassword) {
    const newPassword = stringExtensions.removeEscapeCharacter(data.newPassword)
    queryClause += `, newPassword: "${newPassword}"`
  }
  return queryClause
}

export const querySendPasswordCode = (email) =>{
  const mail = stringExtensions.removeEscapeCharacter(email)
  return `email: "${mail}"`
}