import React, { Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'
import Button from 'antd/lib/button'
import modal from 'antd/lib/modal'
import { Link, withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { MenuOutlined } from '@ant-design/icons'
import classNames from 'classnames'
// action
import authActions from '../../actions/authActions'
//routes
import { routeAuth, routes } from '../../routes/home'
//reselect
import { selectUserLogin } from '../../reselect/auth'
import { selectUserSessions } from '../../reselect/useSession'
import modalActions from '../../actions/modalActions'
import { MODAL_AUTH } from '../../constants'
import { Divider } from 'antd'
// component

const menuUserLogin = ({ isMobile, onLogout }) => (
  <Menu
    className={classNames({
      'cbn-header-menu menu-mobile': isMobile
    })}
    theme='light'
    mode='horizontal'
    overflowedIndicator={isMobile ? <MenuOutlined size={20} /> : null}
  >
    <Menu.Item key={Math.random()}>
      <Link to={routes.ROUTE_PROFILE} className='text-decoration-none'>
        Edit Account
      </Link>
    </Menu.Item>
    <Menu.Item onClick={() => onLogout()} key={0}>
      Logout
    </Menu.Item>
    {/* {jobs && jobs.length > 0
      ? jobs.map((job) => {
          return (
            <Menu.Item key={Math.random()}>
              <Link
                to={`${routes.ROUTE_USER_SESSION}/${job.id}`}
                className='text-decoration-none'
              >
                {job.code}
              </Link>
            </Menu.Item>
          )
        })
      : null} */}
  </Menu>
)

const Profile = (props) => {
  const user = useSelector(selectUserLogin())

  const userSessions = useSelector(selectUserSessions())

  // map dispatch from redux to local
  const dispatch = useDispatch()

  const onLogoutCallback = useCallback(() => dispatch(authActions.logout()), [
    dispatch
  ])

  const openAuthModal = useCallback(
    (options) => dispatch(modalActions.openModal(MODAL_AUTH, options)),
    [dispatch]
  )

  const logout = () => {
    modal.confirm({
      content: 'You are opening a job. Are you sure you want to log out?',
      className: 'text-center',
      cancelText: 'No',
      okText: 'Yes',
      onOk: onLogoutCallback
    })
  }

  return (
    <div className='float-right d-md-block w-100 text-right'>
      {
        user ? (
          isMobile ? (
            menuUserLogin({
              isMobile: isMobile,
              jobs: userSessions,
              onLogout: logout
            })
          ) : (
            <Dropdown
              overlay={menuUserLogin({
                jobs: userSessions,
                onLogout: logout
              })}
            >
              <a onClick={(event) => event.preventDefault()}>
                Hi, {user ? user.firstName : ''}
              </a>
            </Dropdown>
          )
        ) : (
          <Fragment>
            <Button
              className='bg-transparent border-0 px-0'
              onClick={() =>
                openAuthModal({
                  isRegister: false
                })
              }
            >
              Sign In
            </Button>
            <Divider type='vertical' />
            <Link to={routeAuth.REGISTER} className='text-dark mr-3'>
              Register
            </Link>
          </Fragment>
        )
        //   (
        //   <Dropdown
        //     overlay={menuUserNotLogin({
        //       handleLogin: () =>
        //         openAuthModal({
        //           isRegister: false
        //         }),
        //       handleRegister: () =>
        //         openAuthModal({
        //           isRegister: true
        //         })
        //     })}
        //   >
        //     <Button shape='circle' icon={<UserOutlined />} />
        //   </Dropdown>
        // )
      }
    </div>
  )
}

export default withRouter(Profile)
