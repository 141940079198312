import { createSelector } from 'reselect'

const getAuth = (state) => state.auth

const getSendPassCode = state => state.auth?.isSendPassCode ?? false

const getEmailPassCode  = state => state.auth?.emailForgotPass ?? false

export const selectUserLogin = () =>
  createSelector([getAuth], (auth) => auth?.user)

export const selectTokenExpire = () =>
  createSelector([getAuth], (auth) => auth?.expiresIn)

export const selectChangePass = () =>
  createSelector([getAuth], (auth) => !!auth.changePass)

export const selectSendPassCode = () => createSelector(
  [getSendPassCode],
  isSendPassCode => !!isSendPassCode
)

export const selectEmailPassCode = () => createSelector(
  [getEmailPassCode], email => email)
