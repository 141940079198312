import ActionTypes from '../actions/types'

const initialState = {
  user: null,
  expiresIn: null,
  changePass: false,
  isSendPassCode: false,
  emailForgotPass: null
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGGED_IN: {
      return action.payload.data
    }
    case ActionTypes.USER_LOGGED_OUT: {
      return initialState
    }
    case ActionTypes.REFRESH_TOKEN: {
      return Object.assign({}, state, {
        expiresIn: action.payload.data
      })
    }
    case ActionTypes.UPDATE_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, action.payload.data)
      })
    }
    case ActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changePass: !state.changePass
      })
    }
    case ActionTypes.START_SEND_PASSWORD_CODE: {
      return Object.assign({}, state, {
        isSendPassCode: true,
        emailForgotPass: action?.payload || null
      })
    }
    case ActionTypes.RESTORE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        isSendPassCode: false,
        emailForgotPass: null
      })
    }
    default:
      return state
  }
}

export default AuthReducer
