import { deepCopyState } from '.'
import ActionTypes from '../actions/types'
import { intNewTab } from '../extensions/tabs'

export const CHANGE_TAB_STYLE = 'tab/CHANGE_TAB_STYLE'
export const CHANGE_JOB_TAB = 'tab/CHANGE_JOB_TAB'

const initialState = {
  activeTab: 0,
  tabs: [],
  currentTab: null
}

const TabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CURRENT_JOB: {
      if (!action.payload.data) return state

      const listTabs = deepCopyState(state.tabs ?? [])

      let currentTab
      const index = listTabs.findIndex((t) => t.index.toString() === '0')
      if (index < 0) {
        currentTab = {
          ...intNewTab(),
          name: 'Unknown Style',
          alias: 'Unknown Style',
          index: 0,
          currentJob: []
        }
        listTabs.unshift(currentTab)
      } else {
        currentTab = listTabs[index]
      }

      let currentJob = currentTab.currentJob

      const newJob = action.payload.data
      if (currentJob?.length > 0) {
        const job = currentJob.find((j) => j.id === newJob.id)
        if (!job) {
          currentJob.push(newJob)
        }
      } else {
        currentJob = [newJob]
      }

      currentTab.currentJob = currentJob

      listTabs[0] = currentTab

      return Object.assign({}, state, {
        tabs: listTabs,
        currentTab,
        activeTab: 0
      })
    }

    case ActionTypes.REMOVE_CURRENT_JOB: {
      const { jobRemove, index } = action.payload.data

      if (!jobRemove) return state

      const listTabs = deepCopyState(state.tabs)
      const tabIndex = listTabs.findIndex((t) => t.index == index)

      const currentTab = tabIndex < 0 ? null : listTabs[tabIndex]

      if (!currentTab) return state

      if (currentTab.currentJob?.length > 0) {
        const jobs = currentTab.currentJob
        const index = jobs.findIndex((j) => j.id === jobRemove.id)

        if (index >= 0) {
          jobs.splice(index, 1)
          currentTab.currentJob = jobs
        }
      }

      listTabs[tabIndex] = currentTab

      return Object.assign({}, state, {
        currentTab: index == 0 ? currentTab : state.currentTab,
        tabs: listTabs
      })
    }

    case ActionTypes.CHANGE_CURRENT_JOB: {
      const { jobData, index } = action.payload.data
      if (!jobData) return state
      const listTabs = deepCopyState(state.tabs)

      const tabIndex = listTabs.findIndex((t) => t.index == index)

      const currentTab = tabIndex < 0 ? null : listTabs[tabIndex]

      if (!currentTab) return state

      const listJobs = deepCopyState(currentTab?.currentJob ?? [])
      const currentJob = listJobs.find(
        (item) => item.id === jobData.currentJobId
      )

      // console.log('jobData.....', jobData)
      // console.log('currentJob....', currentJob)
      if (currentJob) {
        const property = currentJob.properties.find(
          (item) => item.id === jobData.propertyId
        )
        if (property) {
          property.value = jobData.propertyValue
        }
      }
      currentTab.currentJob = listJobs

      listTabs[tabIndex] = currentTab

      return Object.assign({}, state, {
        currentTab: index == 0 ? currentTab : state.currentTab,
        tabs: listTabs
      })
    }

    case ActionTypes.CLEAR_TABS: {
      return initialState
    }

    case ActionTypes.ADD_NEW_TAB: {
      if (!action.payload.data) return state
      const { addition, data = {} } = action.payload.data
      let add = addition ? Number(addition) : 1
      if (!add) add = 1

      const listTabs = deepCopyState(state.tabs)

      const index = listTabs.findIndex((t) => t.index.toString() === '0')
      if (index < 0) {
        const unknownTab = {
          ...intNewTab(),
          name: 'Unknown Style',
          alias: 'Unknown Style',
          index: 0,
          currentJob: []
        }
        listTabs.unshift(unknownTab)
      }

      let lastIndex = 0

      if (listTabs?.length > 0) {
        const indexes = listTabs.map((t) => Number(t.index) ?? 0)
        lastIndex = Math.max(...indexes)
      }
      const listNewTabs = []
      for (let index = 0; index < add; index++) {
        const tabIndex = (Number(lastIndex) ?? 0) + index + 1

        const name =
          tabIndex <= 1 ? `Style ${tabIndex}` : `Empty Style ${tabIndex}`
        const newTab = {
          ...data,
          name,
          alias: name,
          index: `${tabIndex}`
        }

        listNewTabs.push(newTab)
      }
      lastIndex = (Number(lastIndex) ?? 0) + 1
      listTabs.push(...listNewTabs)

      return Object.assign({}, state, {
        tabs: listTabs,
        currentTab: listNewTabs[0],
        activeTab: `${lastIndex}`
      })
    }

    case ActionTypes.SET_ACTIVE_TAB: {
      if (!action.payload.data) return state
      const activeKey = action.payload.data

      const currentTab =
        state.tabs?.length > 0
          ? state.tabs.find((t) => t.index === activeKey) ?? null
          : null
      return Object.assign({}, state, {
        currentTab: currentTab,
        activeTab: activeKey
      })
    }

    case ActionTypes.REMOVE_CURRENT_TAB: {
      if (!action.payload.data) return state

      const removeKey = action.payload.data

      const listTabs = (state.tabs ?? []).filter(
        (pane) => pane.index !== removeKey
      )

      let currentActiveKey = 0
      const indexes = listTabs.map((t) => t.index)
      if (indexes.length > 0) {
        const smallerIndexes = indexes.filter((x) => x < removeKey)
        if (smallerIndexes.length > 0) {
          currentActiveKey = Math.max(...smallerIndexes)
        } else {
          const largerIndexes = indexes.filter((x) => x > removeKey)
          currentActiveKey = Math.min(...largerIndexes)
        }

        if (!currentActiveKey) {
          currentActiveKey = 0
        }
      }

      // const currentTab =
      //   listTabs.find((t) => t.index == currentActiveKey) ?? null

      return Object.assign({}, state, {
        tabs: listTabs,
        activeTab: `${currentActiveKey}`
        // currentTab: currentTab
      })
    }

    case CHANGE_TAB_STYLE: {
      if (!action.payload.data) return state

      const { style, index, listReadyStyles } = action.payload.data

      const listTabs = deepCopyState(state.tabs)

      const tabIndex = listTabs.findIndex((t) => t.index == index)

      const currentTab = tabIndex < 0 ? null : listTabs[tabIndex]

      if (!currentTab) return state

      if (style) {
        currentTab.style = style ?? null

        let tabAlias = index > 1 ? `Empty Style ${index}` : `Style ${index}`

        if (style.readyStyleId && listReadyStyles?.length > 0) {
          const selectedStyle = listReadyStyles.find(
            (rs) => rs.id === style.readyStyleId
          )
          if (selectedStyle) {
            tabAlias = selectedStyle.name ?? ''
          }
        }

        currentTab.alias = tabAlias

        listTabs[tabIndex] = currentTab
      }

      return Object.assign({}, state, {
        currentTab: index === 0 ? currentTab : state.currentTab,
        tabs: listTabs
      })
    }

    case CHANGE_JOB_TAB: {
      if (!action.payload.data) return state
      const { oldTabIndex, currentTabIndex, jobId } = action.payload.data

      const listTabs = deepCopyState(state.tabs)

      let jobData
      // 1. find old tab
      const previousIndex =
        currentTabIndex == oldTabIndex
          ? -1
          : listTabs.findIndex((t) => t.index == oldTabIndex)
      if (previousIndex >= 0) {
        const previousTab = listTabs[previousIndex]
        if (previousTab?.currentJob?.length > 0) {
          const previousJob = previousTab?.currentJob ?? []
          const jobIndex = previousJob.findIndex((job) => job.id === jobId)

          if (jobIndex >= 0) {
            jobData = previousJob[jobIndex]
            previousJob.splice(jobIndex, 1)

            previousTab.currentJob = previousJob
          }
        }

        listTabs[previousIndex] = previousTab
      }

      // 2. add job to destination tab
      const currentIndex = listTabs.findIndex((t) => t.index == currentTabIndex)

      if (currentIndex >= 0 && jobData) {
        const currentTab = listTabs[currentIndex]
        let currentJob = currentTab.currentJob ?? []
        if (currentJob?.length > 0) {
          currentJob.push(jobData)
        } else {
          currentJob = [jobData]
        }

        currentTab.currentJob = currentJob
        listTabs[currentIndex] = currentTab
      }

      return Object.assign({}, state, {
        tabs: listTabs
      })
    }
    default:
      return state
  }
}

export default TabsReducer
