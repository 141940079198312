import { queryStringExtensions, stringExtensions } from '../extensions'
export default {
  initQueryGetAllFeatureProduct(values, defaultPageSize) {
    let query = `isDeleted: false,  isPublished: true`
    if (values.keyword) {
      const keyword = stringExtensions.removeEscapeCharacter(values.keyword)
      query += `, name: {like: "%${keyword}%"}`
    }

    if (values.feature) {
      const featureId = stringExtensions.removeAccentedCharacter(
        values.feature
      )
      query += `, featureId: "${featureId}"`
    }

    let order = `order`
    return {
      whereClause: `where: {${query}}, order: "${order}"`,
    }
  },
  initQueryGetPagingFeatureProduct(values, defaultPageSize) {
    const { pageSize, skip } = queryStringExtensions.getSizeAndIndexPage(
      values,
      defaultPageSize
    )

    let query = ``
    if (values.keyword) {
      const keyword = stringExtensions.removeEscapeCharacter(values.keyword)
      query += `, name: {like: "%${keyword}%"}`
    }

    if (values.feature) {
      const featureId = stringExtensions.removeAccentedCharacter(
        values.feature
      )
      query += `, featureId: "${featureId}"`
    }

    let order = `order`
    return {
      whereClause: `where: {${query}}, limit: ${pageSize}, offset: ${skip}, order: "${order}"`,
      whereConnectionClause: `where: {isDeleted: false , isPublished: true}, order: "${order}"`
    }
  },

}
