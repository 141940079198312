import { queryStringExtensions, stringExtensions } from '../extensions'

const initQueryFeatureItem = (style) => {
  if (!style) {
    return ''
  }

  let query = ''
  if (style.id) {
    const id = stringExtensions.removeEscapeCharacter(style.id)
    query += `, id: "${id}"`
  }
  if (style.name) {
    const name = stringExtensions.removeEscapeCharacter(style.name)
    query += `, name: "${name}"`
  }
  if (style.description) {
    const description = stringExtensions.removeEscapeCharacter(
      style.description
    )
    query += `, description: "${description}"`
  }
  if (style.images) {
    const images = stringExtensions.removeEscapeCharacter(style.images)
    query += `, images: "${images}"`
  }
  if (style.order || style.order === 0) {
    query += `, order: "${style.order}"`
  }

  return `{${query}}`
}

const initQueryStyle = (style) => {
  let query = ''
  if (style) {
    query += '{'
    if (style.id) {
      const id = stringExtensions.removeEscapeCharacter(style.id)
      query += `, id: "${id}"`
    }

    if (style.featureCategoryId) {
      const featureCategoryId = stringExtensions.removeEscapeCharacter(
        style.featureCategoryId
      )
      query += `, featureCategoryId: "${featureCategoryId}"`
    }

    let featureId = style.featureId
    if (!featureId && style.feature?.id) {
      featureId = style.feature.id
    }

    if (featureId) {
      const fId = stringExtensions.removeEscapeCharacter(featureId)
      query += `, featureId: "${fId}"`
    }

    let featureProductId = style.featureProductId
    if (!featureProductId && style.featureProduct?.id) {
      featureProductId = style.featureProduct.id
    }

    if (featureProductId) {
      const fpId = stringExtensions.removeEscapeCharacter(featureProductId)
      query += `, featureProductId: "${fpId}"`
    }

    let finishListId = style.finishListId
    if (!finishListId && style.finishList?.id) {
      finishListId = style.finishList.id
    }
    if (finishListId) {
      const flId = stringExtensions.removeEscapeCharacter(finishListId)
      query += `, finishListId: "${flId}"`
    }

    if (style.feature) {
      query += `, feature: ${initQueryFeatureItem(style.feature)}`
    }

    if (style.featureProduct) {
      query += `, featureProduct: ${initQueryFeatureItem(style.featureProduct)}`
    }

    if (style.finishList) {
      query += `, finishList: ${initQueryFeatureItem(style.finishList)}`
    }

    query += `, isDefault: ${!!style.isDefault}`

    query += '}'
  }

  return query
}

const initQueryProperty = (property) => {
  let query = '{'
  if (property.id) {
    const id = stringExtensions.removeEscapeCharacter(property.id)
    query += `, id: "${id}"`
  }

  if (property.key) {
    const key = stringExtensions.removeEscapeCharacter(property.key)
    query += `, key: "${key}"`
  }

  if (property.shortDisplayName) {
    const shortDisplayName = stringExtensions.removeEscapeCharacter(
      property.shortDisplayName
    )
    query += `, shortDisplayName: "${shortDisplayName}"`
  }

  if (property.longDisplayName) {
    const longDisplayName = stringExtensions.removeEscapeCharacter(
      property.longDisplayName
    )
    query += `, longDisplayName: "${longDisplayName}"`
  }

  query += `, isShowDefault: ${!!property.isShowDefault}`

  if (property.variables) {
    query += `, variables: "${
      property.variables?.map((variable) => variable.value) ?? ''
    }"`
  }

  if (property.defaultValue || property.defaultValue === 0) {
    query += `, defaultValue: "${property.defaultValue}"`
  }

  if (property.max || property.max === 0) {
    query += `, max: "${property.max}"`
  }

  if (property.min || property.min === 0) {
    query += `, max: "${property.min}"`
  }

  if (property.value || property.value === 0) {
    query += `, value: "${property.value}"`
  }

  query += '}'

  return query
}

export default {
  initQueryUserSessions(values = {}) {
    let query = `isDeleted: false, isPublished: true `
    if (values.user) {
      const userId = stringExtensions.removeEscapeCharacter(values.user)
      query += `, userId: "${userId}"`
    }
    let order = `reverse:code`
    return {
      whereClause: `where: {${query}}, order: "${order}"`
    }
  },
  initQueryUniqueUserSession(id) {
    return `id: "${stringExtensions.removeEscapeCharacter(id)}"`
  },
  initQueryCreateOrUpdateUserSession(values) {
    const { userInfo } = values
    let query = ''
    if (userInfo.code) {
      const code = stringExtensions.removeEscapeCharacter(userInfo.code)
      query += `, code: "${code}"`
    }
    if (userInfo) {
      query += ', userInfo: {'

      if (userInfo.firstName) {
        const firstName = stringExtensions.removeEscapeCharacter(
          userInfo.firstName
        )
        query += `, firstName: "${firstName}"`
      }

      if (userInfo.lastName) {
        const lastName = stringExtensions.removeEscapeCharacter(
          userInfo.lastName
        )
        query += `, lastName: "${lastName}"`
      }

      if (userInfo.phoneNumber) {
        const phoneNumber = stringExtensions.removeEscapeCharacter(
          userInfo.phoneNumber
        )
        query += `, phoneNumber: "${phoneNumber}"`
      }

      if (userInfo.email) {
        const email = stringExtensions.removeEscapeCharacter(userInfo.email)
        query += `, email: "${email}"`
      }

      if (userInfo.address) {
        const address = stringExtensions.removeEscapeCharacter(userInfo.address)
        query += `, address: "${address}"`
      }
      query += '}'
    }

    if (values.activeCategory) {
      const activeCategory = stringExtensions.removeEscapeCharacter(
        values.activeCategory
      )
      query += `, activeCategory: "${activeCategory}"`
    }

    if (values.activeStyle) {
      const activeStyle = stringExtensions.removeEscapeCharacter(
        values.activeStyle
      )
      query += `, activeStyle: "${activeStyle}"`
    }

    if (typeof values.isCollageStyle === 'boolean') {
      query += `, isCollapseStyle: ${!!values.isCollageStyle}`
    } else {
      query += `, isCollapseStyle: true`
    }

    if (values.userSessionStyles?.length > 0) {
      query += `, userSessionStyles: [`
      values.userSessionStyles.map((session, key) => {
        const {
          previewStyle,
          activeTab,
          tabStyles,
          featureCategoryId,
          currentStyle
        } = session || {}
        const id = stringExtensions.removeEscapeCharacter(featureCategoryId)
        query += '{'
        query += `, fcId: "${id}"`
        query += `, activeTab: ${activeTab}`
        if (previewStyle) {
          query += `, previewStyle: ${initQueryStyle(previewStyle)}`
        }

        if (currentStyle) {
          query += `, currentStyle: ${initQueryStyle(currentStyle)}`
        }

        if (tabStyles?.length > 0) {
          query += ', tabStyles: ['
          tabStyles.forEach((tab) => {
            query += '{'
            const tabKey = stringExtensions.removeEscapeCharacter(tab.tabKey)
            if (tabKey) {
              query += `tabKey: "${tab.tabKey}"`
            }

            const tabName = stringExtensions.removeEscapeCharacter(tab.tabName)
            if (tabName) {
              query += `, tabName: "${tabName}"`
            }

            if (tab.styleItem) {
              query += `, styleItem: ${initQueryStyle(tab.styleItem)}`
            }

            query += '}, '
          })

          query += ']'
        }
        query += '}'
      })
      query += `]`
    }

    if (values.currentJob?.length > 0) {
      query += ', currentJob: ['
      values.currentJob.forEach((job) => {
        query += '{'
        if (job.productId) {
          const productId = stringExtensions.removeEscapeCharacter(
            job.productId
          )
          query += `, productId: "${productId}"`
        }

        if (job.properties?.length > 0) {
          query += ', properties: ['
          job.properties.forEach((property) => {
            query += initQueryProperty(property)
          })
          query += ']'
        }
        query += '}'
      })
      query += ']'
    }

    return `data: {${query}}`
  }
}
