import { show, hide } from 'redux-modal'

const modalActions = {
  openModal(name, options) {
    return dispatch => {
      dispatch(show(name, options))
    }
  },
  hiddenModal(name) {
    return dispatch => {
      dispatch(hide(name))
    }
  }
}

export default modalActions
