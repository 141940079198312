import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import Button from 'antd/lib/button'
import modalActions from '../../actions/modalActions'
import {MODAL_AUTH} from '../../constants'
import {withRouter, Link} from 'react-router-dom'
import {routeAuth} from '../../routes/home'

const SuggestRegister = (props) => {
  const {isPopup = false, history} = props
  const dispatch = useDispatch()

  const openAuthModal = useCallback(
    (options) => dispatch(modalActions.openModal(MODAL_AUTH, options)),
    [dispatch]
  )

  const hiddenModalAuth = useCallback(
    () => dispatch(modalActions.hiddenModal(MODAL_AUTH)),
    [dispatch]
  )

  return (
    <div className='text-center mt-2'>
      Do not have account?{' '}
      <span>
        <Button
          className='bg-transparent border-0 px-0 text-primary'
          onClick={() => {
            hiddenModalAuth()
            isPopup
              ? openAuthModal({
                isRegister: true
              })
              : history.push(routeAuth.REGISTER)
          }}
        >
          Register new account
        </Button>
        <div className={'text-center'}>
          <Button
            className='bg-transparent border-0 px-0 text-primary'
            onClick={() => {
              hiddenModalAuth()
              history.push(routeAuth.FORGOT_PASSWORD)
            }}
          >
          Forgot password
        </Button>
        </div>
      </span>
    </div>
  )
}

export default withRouter(SuggestRegister)
