import React, { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { connectModal } from 'redux-modal'
import Modal from 'antd/lib/modal'
import FormLogin from '../../view/Authentication/Login/Form'
import FormRegister from '../../view/Authentication/Register/Form'
import { useDispatch } from 'react-redux'
import authActions from '../../actions/authActions'
import { resetFormFieldError } from '../../actions/formActions'
import { queryRegister } from '../../clauseSearch/auth'
import { MODAL_AUTH } from '../../constants'
import { stringExtensions } from '../../extensions'
import { TrophyOutlined } from '@ant-design/icons'

const ModalAuth = (props) => {
  const { show, handleHide, title, className, isRegister } = props

  const dispatch = useDispatch()

  const loginCallback = useCallback(
    (queryClause, pathRedirect) =>
      dispatch(authActions.login(queryClause, pathRedirect)),
    [dispatch]
  )

  const registerCallback = useCallback(
    (queryClause, pathRedirect) =>
      dispatch(authActions.register(queryClause, pathRedirect)),
    [dispatch]
  )

  const resetFormCallback = useCallback(() => dispatch(resetFormFieldError()), [
    dispatch
  ])

  useEffect(() => {
    resetFormCallback()
  }, [])

  const handleLogin = (values) => {
    const email = stringExtensions.removeEscapeCharacter(values.email)
    const password = stringExtensions.removeEscapeCharacter(values.password)
    const queryClause = `email: "${email}", password: "${password}"`
    loginCallback(queryClause)
  }

  const handleRegister = (values) => {
    const query = queryRegister(values)
    registerCallback(query)
  }

  return (
    <Modal
      centered={true}
      visible={show}
      mask={true}
      closable={true}
      footer={false}
      maskClosable={true}
      className={classNames('custom-modal custom-modal-auth', className)}
      title={
        <h4 className='text-center'>
          <strong>
            {isRegister
              ? 'Create an account if you have not one'
              : 'Login to save your jobs'}
          </strong>
        </h4>
      }
      onCancel={() => {
        handleHide()
      }}
      onOk={() => {
        handleHide()
      }}
    >
      <div className={'form'}>
        {isRegister ? (
          <FormRegister handleSubmitForm={handleRegister} isPopup={true} />
        ) : (
          <FormLogin handleSubmitForm={handleLogin} isPopup={false} />
        )}
      </div>
    </Modal>
  )
}

export default connectModal({ name: MODAL_AUTH })(ModalAuth)
