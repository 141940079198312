export default {
  loadDataPager(queryClause) {
    return `
    query{
      searchFinishListByProduct(${queryClause.whereClause}){
        id
        name
        images
        isDeleted
        isPublished
        createdAt
        description
      }
    }  
    `
  }
}
