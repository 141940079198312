export default {
  loadDataPager(queryClause) {
    return `
    query {
      features(${queryClause.whereClause}) {
        id
        name
        images
        isDeleted
        order
        featureCategory{
          id
          name
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      feature(${queryClause}) {
        id
        name
        images
        isDeleted
        order
        featureCategory{
          id
          name
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createFeature(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateFeature(${queryClause}) {
        id
      }
    }    
    `
  }
}
