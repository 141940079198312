const ActionTypes = {
  //authentications
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  CHANGE_PASSWORD_SUCCESS: 'fetching/CHANGE_PASSWORD_SUCCESS',
  RESET_CHANGE_PASSWORD_SUCCESS: 'fetching/RESET_CHANGE_PASSWORD_SUCCESS',
  UPDATE_PROFILE_SUCCESS: 'fetching/UPDATE_PROFILE_SUCCESS',
  START_SEND_PASSWORD_CODE: 'fetching/START_SEND_PASSWORD_CODE',
  RESTORE_PASSWORD_SUCCESS: 'fetching/RESTORE_PASSWORD_SUCCESS',
  // redirect
  REDIRECT_URL: 'fetching/REDIRECT_URL',
  // fetching
  START_FETCHING: 'fetching/START_FETCHING',
  FETCH_SUCCESS: 'fetching/FETCH_SUCCESS',
  START_FETCHING_SELECT: 'fetching/START_FETCHING_SELECT',
  STOP_FETCHING_SELECT: 'fetching/STOP_FETCHING_SELECT',
  FETCH_PAGER_SUCCESS: 'fetching/FETCH_PAGER_SUCCESS',
  CREATE_SUCCESS: 'fetching/CREATE_SUCCESS',
  UPDATE_SUCCESS: 'fetching/UPDATE_SUCCESS',
  DELETE_SUCCESS: 'fetching/DELETE_SUCCESS',
  UPDATE_MANY_SUCCESS: 'fetching/UPDATE_MANY_SUCCESS',
  CLEAR_SUCCESS: 'fetching/CLEAR_SUCCESS',
  STOP_FETCHING: 'fetching/STOP_FETCHING',
  START_FETCH_DATA_SUCCESS: 'fetching/START_FETCH_DATA_SUCCESS',
  STOP_FETCH_DATA_SUCCESS: 'fetching/STOP_FETCH_DATA_SUCCESS',
  START_FETCH_FEATURE_PRODUCT: 'fetching/START_FETCH_FEATURE_PRODUCT',
  STOP_FETCH_FEATURE_PRODUCT: 'fetching/STOP_FETCH_FEATURE_PRODUCT',
  // notification
  SHOW_NOTIFICATION: 'notification/SHOW_NOTIFICATION',
  RESET_NOTIFICATION: 'notification/RESET_NOTIFICATION',

  // form
  FETCH_FORM_FIELD_ERROR_SUCCESS: 'form/FETCH_FORM_FIELD_ERROR',
  FETCH_RESET_FORM_FIELD_ERROR_SUCCESS:
    'form/FETCH_RESET_FORM_FIELD_ERROR_SUCCESS',

  // product
  FETCHING_PRODUCT_SUCCESS: 'product/FETCHING_PRODUCT_SUCCESS',
  UPDATE_CATEGORY_SUCCESS: 'product/UPDATE_CATEGORY_SUCCESS',
  UPDATE_PRODUCT_SUCCESS: 'product/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PROPERTY_SUCCESS: 'product/UPDATE_PROPERTY_SUCCESS',

  // current job
  CLEAR_USER_SESSION: 'fetching/CLEAR_USER_SESSION',
  FETCH_LOAD_USER_SESSION: 'fetching/FETCH_LOAD_USER_SESSION',
  SET_USER_SESSION: 'fetching/SET_USER_SESSION',
  SET_ACTIVE_STYLE: 'fetching/SET_ACTIVE_STYLE',
  SET_COLLAPSE_STYLE: 'fetching/SET_COLLAPSE_STYLE',
  SET_FEATURE_CATEGORY: 'fetching/SET_FEATURE_CATEGORY',
  ADD_CURRENT_JOB: 'fetching/ADD_CURRENT_JOB',
  REMOVE_CURRENT_JOB: 'fetching/REMOVE_CURRENT_JOB',
  CHANGE_CURRENT_JOB: 'fetching/CHANGE_CURRENT_JOB',
  ACTIVE_TAB: 'fetching/ACTIVE_TAB',
  REPLACE_CONTENT_TAB: 'fetching/REPLACE_CONTENT_TAB',
  SET_ACTIVE_CATEGORY: 'fetching/SET_ACTIVE_CATEGORY',
  SET_USER_INFO: 'fetching/SET_USER_INFO',
  CHANGE_USER_INFO: 'fetching/CHANGE_USER_INFO',

  //readyStyles
  CLEAR_TABS: 'fetching/CLEAR_TABS',
  ADD_NEW_TAB: 'fetching/ADD_NEW_TAB',
  REMOVE_CURRENT_TAB: 'fetching/REMOVE_CURRENT_TAB',
  SET_ACTIVE_TAB: 'fetching/SET_ACTIVE_TAB',

  //readyStyles
  LOAD_READY_STYLE: 'fetching/LOAD_READY_STYLE',
  CLEAR_READY_STYLE: 'fetching/CLEAR_READY_STYLE',
  ADD_NEW_READY_STYLE: 'fetching/ADD_NEW_READY_STYLE',
  REMOVE_CURRENT_READY_STYLE: 'fetching/REMOVE_CURRENT_READY_STYLE',
  SAVE_CURRENT_READY_STYLE: 'fetching/SAVE_CURRENT_READY_STYLE',
  SET_ACTIVE_READY_STYLE: 'fetching/SET_ACTIVE_READY_STYLE',
  CREATE_READY_STYLE_SUCCESS: 'fetching/CREATE_READY_STYLE_SUCCESS',
}

export default ActionTypes
