import {
  FORMAT_DATE_TIME_STRING,
  formatDateTimeToString,
  getNewDate
} from '../extensions/dateTime'
import stringExtensions from '../extensions/string'
import {
  DEFAULT_PREFIX,
  SUFFIX_SESSION_CODE_LENGTH,
  TAB_STYLES
} from '../constants'
import { initProperties } from './product'

export const initUserInfo = (data) => {
  if (!data) return null
  return {
    code: data.code
      ? data.code
      : data.name
      ? data.name
      : initJobCode({ backSpace: '_' }),
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    email: data.email,
    address: data.address
  }
}

const initJobCode = ({ prefix = DEFAULT_PREFIX, backSpace = '' }) => {
  return `${prefix}${backSpace}${formatDateTimeToString(
    getNewDate(),
    FORMAT_DATE_TIME_STRING
  )}`
}

export const initUserCurrentJob = (data) => {
  if (!data || data.length === 0) return []
  const listJobs = data.map((job) => {
    const product = job.product
    return {
      id: job.id,
      productId: product?.id,
      name: product?.name,
      images: product?.images,
      properties: initProperties(job.properties)
    }
  })
  return listJobs
}

export const mapUserSessionWithState = (data, initialState) => {
  const objectUserSession = initialState
  if (!data) return objectUserSession
  return {
    ...objectUserSession,
    id: data.id,
    activeStyle: data.activeStyle,
    isCollageStyle: data.isCollageStyle,
    userInfo: initUserInfo(data),
    userSessionStyles: data.userSessionStyles,
    activeCategory: data.activeCategory,
    currentJob: initUserCurrentJob(data.userSessionJobs)
  }
}

export const getItemActive = ({
  currentActive,
  data,
  isFirstDefault = true
}) => {
  if (!data || data.length === 0) return null
  if (currentActive) {
    const item = data.find((i) => i.id === currentActive)
    return item ? item : isFirstDefault ? data[0] : null
  }
  return isFirstDefault ? data[0] : null
}

export const previewUserSession = (data, isDefault = false) => {
  if (!isDefault) {
    return data
  }
  const feature =
    data.features && data.features.length > 0 ? data.features[0] : null
  return {
    featureCategoryId: data.id,
    featureId: feature?.id,
    feature: feature,
    featureProductId: null,
    featureProduct: null,
    finishListId: null,
    finishList: null,
    isDefault: true
  }
}

const initTabStyle = (data, key) => {
  return {
    tabKey: key.toString(),
    tabName: `Style ${key + 1}`,
    styleItem: data
  }
}

const initTabsStyles = (data, previewStyle) => {
  const tabStyles = []
  for (let i = 0; i < TAB_STYLES; i++) {
    let styleItem = data?.styleItem ?? null
    if (!styleItem && i === 0) {
      styleItem = previewStyle
    }
    tabStyles.push(initTabStyle(styleItem, i))
  }
  return tabStyles
}

export const initUserSessionStyleItem = (style, activeTab = '0') => {
  const previewStyle = style ? previewUserSession(style) : null
  return {
    // id: style?.id ?? null,
    featureCategoryId: style?.featureCategoryId ?? null,
    previewStyle: null,
    activeTab: `${activeTab}`,
    currentStyle: previewStyle,
    tabStyles: style ? initTabsStyles(style, previewStyle) : []
  }
}

export const initUserSessionStyles = (listCurrentStyles, value) => {
  const { data: selectedStyle, featureCategories } = value
  if (!selectedStyle) return listCurrentStyles
  const styles = JSON.parse(JSON.stringify(listCurrentStyles)) || []
  if (!styles || styles.length === 0) {
    if (featureCategories?.length > 0) {
      featureCategories.forEach((fc) => {
        styles.push(initUserSessionStyleItem(fc.defaultStyle))
      })
    } else {
      styles.push(initUserSessionStyleItem(selectedStyle))
    }
  } else {
    const findItem = styles.find(
      (item) => item.featureCategoryId === selectedStyle.featureCategoryId
    )
    if (!findItem) {
      styles.push(initUserSessionStyleItem(selectedStyle))
    }
  }
  return styles
}

export const replaceContentTab = ({
  active,
  currentStyles,
  isReplace,
  isDelete,
  featureCategoryId
}) => {
  if (!active) return currentStyles
  const styles = JSON.parse(JSON.stringify(currentStyles)) || []
  if (styles?.length > 0) {
    const index = styles.findIndex(
      (style) => style.featureCategoryId === featureCategoryId
    )
    if (index >= 0) {
      const currentStyle = styles[index]
      const previewData = currentStyle.previewStyle
      const activeTabKey = currentStyle.activeTab
      const tabStyles = currentStyle.tabStyles

      updateTabStyle({
        activeTabKey,
        tabStyles,
        styleItem: isReplace ? previewData : isDelete ? {} : null
      })

      const newStyle = {
        ...styles[index],
        tabStyles: tabStyles
      }

      if (isReplace) {
        newStyle.previewStyle = null
      }
      styles.splice(index, 1, newStyle)
    }
  }
  return styles
}

const updateTabStyle = ({ tabStyles, activeTabKey, styleItem }) => {
  if (tabStyles && tabStyles.length > 0) {
    const indexTab = tabStyles.findIndex(
      ({ tabKey }) => tabKey === activeTabKey
    )
    if (indexTab >= 0) {
      const tabStyle = {
        ...tabStyles[indexTab],
        styleItem: styleItem ?? tabStyles[indexTab].styleItem
      }
      tabStyles.splice(indexTab, 1, tabStyle)
    }
  }
}

export const setPreviewUserSessionStyles = (
  active,
  listCurrentStyles,
  data,
  isSetPreview
) => {
  if (!data || !active) return listCurrentStyles
  const styles = JSON.parse(JSON.stringify(listCurrentStyles)) || []
  if (styles?.length > 0) {
    const indexItem = styles.findIndex(
      (item) => item.featureCategoryId === active
    )
    if (indexItem >= 0) {
      const currentStyle = styles[indexItem]

      const tabStyles = currentStyle.tabStyles

      const currentActiveTab = tabStyles?.find(
        ({ tabKey }) => tabKey === currentStyle.activeTab
      )

      const previewData = {
        ...currentStyle.currentStyle,
        ...data,
        isDefault: false
      }

      if (
        !currentActiveTab?.styleItem ||
        !currentActiveTab.styleItem.finishList
      ) {
        console.log('0...')
        updateTabStyle({
          activeTabKey: currentStyle.activeTab,
          tabStyles: tabStyles,
          styleItem: data
        })

        console.log('tabStyles...', tabStyles)

        styles.splice(indexItem, 1, {
          ...styles[indexItem],
          currentStyle: previewData,
          tabStyles
        })

        return styles
      }

      styles.splice(indexItem, 1, {
        ...styles[indexItem],
        previewStyle: isSetPreview ? previewData : null,
        currentStyle: previewData
      })
    }
  }
  return styles
}

export const setValueUserSessionStyles = (active, listCurrentStyles, data) => {
  if (!data || !active) return listCurrentStyles
  const styles = JSON.parse(JSON.stringify(listCurrentStyles)) || []
  if (styles && styles.length > 0) {
    const indexItem = styles.findIndex(
      (item) => item.featureCategoryId === data.featureCategoryId
    )
    if (indexItem >= 0) {
      styles.splice(indexItem, 1, {
        ...styles[indexItem],
        activeTab: data.activeTab
      })
    } else {
      const defaultStyle =
        data.defaultStyles?.length > 0
          ? data.defaultStyles.find(
              ({ featureCategoryId }) =>
                featureCategoryId === data.featureCategoryId
            )
          : null
      styles.push(initUserSessionStyleItem(defaultStyle, data.activeTab))
    }
  }
  return styles
}

export const generateSessionCode = (user = {}, totalSessions = 0) => {
  let code = ''
  if (user?.firstName) {
    code += `${stringExtensions.getAbbreviate(user.firstName)?.toUpperCase()}`
  }

  if (user?.lastName) {
    code += `${stringExtensions.getAbbreviate(user.lastName)?.toUpperCase()}`
  }

  if (code.length > 0) {
    code += '_'
  }

  const remainLength =
    SUFFIX_SESSION_CODE_LENGTH - totalSessions.toString().length
  code += ''.padEnd(remainLength, '0')
  code += `${totalSessions + 1}`
  return code
}

export const generateUserCode = (user = {}, accountNumber = 0) => {
  let code = ''
  const remainLength =
    SUFFIX_SESSION_CODE_LENGTH - accountNumber.toString().length
  code += ''.padEnd(remainLength, '0')
  code += `${accountNumber}`

  if (code.length > 0) {
    code += '_'
  }

  if (user?.firstName) {
    code += `${stringExtensions.getAbbreviate(user.firstName)?.toUpperCase()}`
  }

  if (user?.lastName) {
    code += `${stringExtensions.getAbbreviate(user.lastName)?.toUpperCase()}`
  }

  return code
}
