import { deepCopyState } from '.'

export const FETCHING_LIST_CATEGORIES = 'category/FETCHING_LIST_CATEGORIES'
export const LOAD_LIST_CATEGORIES = 'category/LOAD_LIST_CATEGORIES'

export const FETCHING_PRODUCT_BY_CATEGORY =
  'category/FETCHING_PRODUCT_BY_CATEGORY'
export const LOAD_LIST_PRODUCTS = 'category/LOAD_LIST_PRODUCTS'
export const CHANGE_ACTIVE_CATEGORY = 'category/CHANGE_ACTIVE_CATEGORY'

const initState = {
  loadingList: false,
  loadingProduct: false,
  categories: [],
  subCategories: [],
  activeCategory: null,
  categorySelected: []
}

const CategoryReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCHING_LIST_CATEGORIES: {
      const loading = !!action.payload.data
      return Object.assign({}, state, {
        loadingList: loading
      })
    }

    case FETCHING_PRODUCT_BY_CATEGORY: {
      const loading = !!action.payload.data
      return Object.assign({}, state, {
        loadingProduct: loading
      })
    }

    case LOAD_LIST_CATEGORIES: {
      let categories = action.payload.data
      if (!categories || categories.length === 0) {
        categories = []
      }

      return Object.assign({}, state, {
        categories,
        loadingList: false
      })
    }

    case LOAD_LIST_PRODUCTS: {
      if (!action.payload.data) return state
      const { subList, categoryId } = action.payload.data

      const categorySelected = deepCopyState(state.categorySelected)
      let allSubCategories = state.subCategories ?? []
      if (categoryId && categorySelected.indexOf(categoryId) < 0) {
        categorySelected.push(categoryId)
      }

      if (subList?.length > 0) {
        subList.forEach((cat) => {
          //console.log('cat..........', cat)
          allSubCategories.push({
            ...cat,
            parent: categoryId
          })
        })
      }

      return Object.assign({}, state, {
        categorySelected,
        subCategories: allSubCategories,
        activeCategory: categoryId,
        loadingProduct: false
      })
    }

    case CHANGE_ACTIVE_CATEGORY: {
      return Object.assign({}, state, {
        activeCategory: action.payload.data ?? null
      })
    }

    default: {
      return state
    }
  }
}

export default CategoryReducer
