export const ACTION_RBAC = {
  WRITE: 'Write',
  READ: 'Read',
  NO_CONTROL: 'NoControl'
}

export const ACTION_CRUD = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete'
}

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
}

export const CONFIRM_TYPE = {
  DELETE: 'delete',
  PUBLISH: 'publish',
  HIDE: 'hide',
  RESTORE: 'restore',
  REMOVE: 'remove',
  RESET: 'reset',
  LOCK: 'lock'
}

export const STATUS = {
  ACTIVE: true,
  DELETE: false
}

export const INPUT_TYPE = {
  INPUT: 'input',
  NUMBER: 'number',
  SELECT: 'select'
}

export const MIX_TYPE_DATA = {
  CATEGORY: 'Category',
  PRODUCT: 'Product',
  PROPERTY: 'Property'
}

export const FEATURE_TYPE = {
  STYLE: 'Style',
  MATERIAL: 'Material'
}

export const VIEW_MODE = {
  PREVIEW: 'preview',
  DETAIL: 'detail'
}
