import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  Suspense
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Layout from 'antd/lib/layout'
import Header from './Header'
import AppRoute from '../../routes'
import authActions from '../../actions/authActions'
import { selectTokenExpire } from '../../reselect/auth'
import { COOKIE_REFRESH_AUTH, REFRESH_BEFORE_EXPIRE } from '../../constants'
import { isFutureDate, getNewDate } from '../../extensions/dateTime'
import { getCookie } from '../../extensions/cookie'
import { stringExtensions } from '../../extensions'
import ModalAuth from '../Modal/ModalAuth'
import ModalMessage from '../Modal/ModalMessage'
import ModalConfirm from '../Modal/ModalConfirm'
import { Spin } from 'antd'
const { Content } = Layout

const LayoutPage = () => {
  const dispatch = useDispatch()

  const expiresIn = useSelector(selectTokenExpire())
  const refreshCookie = getCookie(COOKIE_REFRESH_AUTH)

  const logoutCallback = useCallback(() => dispatch(authActions.logout()), [
    dispatch
  ])

  const refreshTokenCallback = useCallback(
    (queryClause) => dispatch(authActions.refreshToken(queryClause)),
    [dispatch]
  )

  useEffect(() => {
    window.clearTimeout()
    if (expiresIn) {
      if (isFutureDate(expiresIn, getNewDate())) {
        const timeOut =
          getNewDate(expiresIn) - getNewDate() - REFRESH_BEFORE_EXPIRE
        if (timeOut > 0) {
          setTimeout(() => {
            handleRefreshToken()
          }, timeOut)
        } else {
          handleRefreshToken()
        }
      } else {
        logoutCallback()
      }
    }
  }, [refreshCookie, expiresIn])

  const handleRefreshToken = () => {
    const refreshToken = stringExtensions.removeEscapeCharacter(refreshCookie)
    const queryClause = `token: "${refreshToken}"`
    refreshTokenCallback(queryClause)
  }

  return (
    <Layout>
      <Header />
      <Layout className='bg-white'>
        <Content>
          <Suspense fallback={<Spin />}>
            <AppRoute />
          </Suspense>

          <ModalAuth />
          <ModalMessage />
          <ModalConfirm />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutPage
