import React from 'react'
import AntMenu from 'antd/lib/menu'
import { MenuOutlined } from '@ant-design/icons'
import { HEADER_MENU } from '../../constants/menu'

const { Item } = AntMenu

const Menu = () => {
  return (
    <div className={'d-none d-sm-block'}>
      <AntMenu
        className='cbn-header-menu'
        theme='light'
        mode='horizontal'
        overflowedIndicator={<MenuOutlined size={20} />}
      >
        {HEADER_MENU.map((menu, index) => (
          <Item key={index}>{menu.title}</Item>
        ))}
      </AntMenu>
    </div>
  )
}

export default Menu
