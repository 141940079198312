export const GRAPHQL_PATH = ''

export const APPLY_VERIFICATION_CODE =
  process.env.REACT_APP_APPLY_VERIFICATION_CODE === 'true'

export const DEFAULT_PAGE_INDEX = 1

export const DEFAULT_PAGE_SIZE = 10

export const DEFAULT_PROP_DESCRIPTION_ROW = 5

export const DEFAULT_WIDTH_MODAL = 500

export const WIDTH_MODAL_JOB_ITEM = 900

export const REFRESH_BEFORE_EXPIRE = 60 * 1000

export const DEFAULT_THUMBNAIL = require('../img/default-images.png')

export const DEFAULT_LABEL = 'Label item'

export const UPLOAD_PRODUCT = 'uploads/products'

export const UPLOAD_FEATURE = 'uploads/feature'

export const COOKIE_AUTH = 'auth'

export const COOKIE_REFRESH_AUTH = 'refresh_auth'

export const COOKIE_VERIFICATION_CODE = 'verification_code'

export const GUEST = 'Guest'

export const EXPIRES_DAY_COOKIES = 7

export const GUEST_EMAIL_ACC = 'guest@happycabinetdesigner.com'

export const DEFAULT_PREFIX = 'JOB'

export const CABINET_USER = {
  firstName: 'John',
  lastName: 'Doe',
  phoneNumber: '(257) 563-7401',
  email: 'JohnDoe@domain.com',
  address: 'Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522'
}

export const TAB_STYLES = 10

export const SUFFIX_SESSION_CODE_LENGTH = 12

export const MODAL_AUTH = 'modalAuth'

export const MODAL_MESSAGE = 'modalMessage'

export const MODAL_CONFIRM = 'modalConfirm'

export const MODAL_STYLE = 'modalStyle'
