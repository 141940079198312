import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getIn, useFormikContext } from 'formik'

const ErrorMessage = (props) => {
  const { customClass, fieldName, isValidate = true } = props

  const { errors, touched } = useFormikContext()

  const hasError =
    typeof getIn(errors, fieldName) === 'string' && getIn(touched, fieldName)
  //console.log('show error of', fieldName, isValidate, errors, touched)
  return isValidate && hasError ? (
    <div className={classnames('custom-error', customClass)}>
      {getIn(errors, fieldName)}
    </div>
  ) : null
}

ErrorMessage.propTypes = {
  fieldName: PropTypes.string,
  isValidate: PropTypes.bool
}

export default ErrorMessage
