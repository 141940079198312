export default {
  loadDataPager(queryClause) {
    return `
       query {
          searchProductByCategory(${queryClause.productClause}) {
            categoryId
            categoryName
            products {
              id
              name
              images
              properties(${queryClause.propertiesClause}) {
                id
                key
                shortDisplayName
                longDisplayName
                description
                defaultValue
                isShowDefault
                variables
                defaultInch
              }
            }
          }
        }
    `
  },

  loadData(queryClause) {
    return `
    query {
      product(${queryClause}) {
        id
        name
        images
        order
        category {
          id
          name
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createProduct(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateProduct(${queryClause}) {
        id
      }
    }    
    `
  }
}
