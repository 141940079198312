import Notifications from 'react-notification-system-redux'
import ActionTypes from './types'
import query from './queries'
import axiosProvider from './api/axiosProvider'
import { objectExtensions } from '../extensions'
import { initCurrentJob } from '../extensions/product'
import { setFormFieldError } from './formActions'
import { GRAPHQL_PATH } from '../constants'
import userSessionClause from '../clauseSearch/userSession'
import dataActions from './dataActions'
import {
  FEATURE_CATEGORY_PATH,
  FEATURE_PRODUCT_PATH,
  FINISH_LIST_PATH,
  USER_SESSION_PATH
} from '../constants/path'
import featureProductClause from '../clauseSearch/featureProduct'
import finishListClause from '../clauseSearch/finishList'
import {
  loadFail,
  loadSuccess,
  redirectPath,
  startFetchingAction,
  startFetchingFeatureProduct,
  stopFetchingAction,
  stopFetchingFeatureProduct,
  downloadFile
} from './commonActions'
import { routes } from '../routes/home'
import { generatePathWithDomain } from '../extensions/image'

const userSessionActions = {
  // addCurrentJob(product) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.ADD_CURRENT_JOB,
  //       payload: { data: initCurrentJob(product) }
  //     })
  //   }
  // },
  //
  // removeCurrentJob(currentJob) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.REMOVE_CURRENT_JOB,
  //       payload: { data: currentJob }
  //     })
  //   }
  // },

  // changePropertyItem(currentJob) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.CHANGE_CURRENT_JOB,
  //       payload: { data: currentJob }
  //     })
  //   }
  // },

  clearSuccess() {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.CLEAR_USER_SESSION
      })
    }
  },

  setFeature(data = null) {
    return async (dispatch) => {
      try {
        dispatch(startFetchingFeatureProduct())
        dispatch(
          dataActions.resetData('featureProducts', [], FEATURE_PRODUCT_PATH)
        )
        dispatch(
          dataActions.resetData(
            'searchFinishListByProduct',
            [],
            FINISH_LIST_PATH
          )
        )
        if (data) {
          dispatch(
            this.loadSuccess({
              actionType: ActionTypes.SET_FEATURE_CATEGORY,
              data: {
                featureId: data.id,
                feature: data
              }
            })
          )
          const clause = featureProductClause.initQueryGetAllFeatureProduct({
            feature: data.id
          })
          await dispatch(
            dataActions.loadDataPager(clause, FEATURE_PRODUCT_PATH)
          )
          dispatch(this.setFeatureProduct(null, false, data))
          dispatch(this.setFinishList(null, null, data))
        }
        dispatch(stopFetchingFeatureProduct())
      } catch (error) {
        dispatch(stopFetchingFeatureProduct())
      }
    }
  },

  setFeatureProduct(data = null, isDefaultFinishList = false, feature = null) {
    return async (dispatch) => {
      dispatch(stopFetchingFeatureProduct())
      try {
        await dispatch(
          dataActions.resetData(
            'searchFinishListByProduct',
            [],
            FINISH_LIST_PATH
          )
        )
        dispatch(
          this.loadSuccess({
            actionType: ActionTypes.SET_FEATURE_CATEGORY,
            data: {
              featureProductId: data?.id || null,
              featureProduct: data,
              feature,
              featureId: feature?.id
            }
          })
        )
        if (data) {
          const clause = finishListClause.initQueryFinishLists({
            product: data.id
          })
          await dispatch(dataActions.loadDataPager(clause, FINISH_LIST_PATH))
          if (!isDefaultFinishList) {
            dispatch(this.setFinishList(null, data, feature))
          }
        }
      } catch (error) {}
    }
  },

  setFinishList(finishList, featureProduct, feature, isSetPreview = true) {
    return async (dispatch) => {
      dispatch(
        this.loadSuccess({
          actionType: ActionTypes.SET_FEATURE_CATEGORY,
          data: {
            finishListId: finishList?.id || null,
            finishList: finishList,
            featureProduct,
            featureProductId: featureProduct?.id,
            feature,
            featureId: feature?.id
          },
          isSetPreview
        })
      )
    }
  },

  setActiveStyle(isCollapseStyle = false, data = null) {
    return async (dispatch, getState) => {
      const menuFc = getState().data?.get(FEATURE_CATEGORY_PATH) ?? null
      dispatch(
        this.loadSuccess({
          actionType: isCollapseStyle
            ? ActionTypes.SET_COLLAPSE_STYLE
            : ActionTypes.SET_ACTIVE_STYLE,
          data: {
            data,
            featureCategories: menuFc?.menuFeatureCategories ?? []
          }
        })
      )
      if (!isCollapseStyle) {
        await dispatch(this.loadSessionActive())
      }
    }
  },

  loadSessionActive() {
    return async (dispatch, getState) => {
      dispatch(
        dataActions.resetData('featureProducts', [], FEATURE_PRODUCT_PATH)
      )
      dispatch(
        dataActions.resetData('searchFinishListByProduct', [], FINISH_LIST_PATH)
      )
      const stateUserSession = await getState().userSession
      if (stateUserSession) {
        //fínd item active
        const userSessionStyles = stateUserSession.userSessionStyles || []
        const userSessionStyleActive = userSessionStyles.find(
          (item) => item.featureCategoryId === stateUserSession.activeStyle
        )
        if (userSessionStyleActive) {
          const { currentStyle, previewStyle } = userSessionStyleActive

          const feature = currentStyle?.feature
          const featureProductId = currentStyle?.featureProductId
          const finishList = currentStyle?.finishList

          if (feature) {
            const clause = featureProductClause.initQueryGetAllFeatureProduct({
              feature: feature?.id
            })
            await dispatch(
              dataActions.loadDataPager(clause, FEATURE_PRODUCT_PATH)
            )
            if (featureProductId) {
              const clause = finishListClause.initQueryFinishLists({
                product: featureProductId
              })
              await dispatch(
                dataActions.loadDataPager(clause, FINISH_LIST_PATH)
              )
              if (finishList) {
                await dispatch(
                  this.setFinishList(
                    currentStyle?.finishList,
                    currentStyle?.featureProduct,
                    currentStyle?.feature,
                    !!previewStyle
                  )
                )
              }
            }
          }
        }
      }
    }
  },

  loadSuccess({ actionType, data, ...rest }) {
    return (dispatch) => {
      dispatch({
        type: actionType,
        payload: { data: data, ...rest }
      })
    }
  },

  loadData(objectId, pathQuery) {
    return async (dispatch) => {
      try {
        this.clearSuccess()
        //console.log('reload suceesss')
        if (objectId) {
          //console.log('has object.....', objectId)
          const clause = userSessionClause.initQueryUniqueUserSession(objectId)

          const queryData = query[pathQuery].loadData(clause)

          const response = await axiosProvider().post(GRAPHQL_PATH, {
            query: `${queryData}`,
            variables: null
          })

          if (response.status === 200 && !response.data.errors) {
            await dispatch(
              this.loadSuccess({
                actionType: ActionTypes.FETCH_LOAD_USER_SESSION,
                data: response?.data?.data?.userSession
              })
            )

            // dispatch(this.loadSessionActive())
          } else {
            dispatch(
              Notifications.error({
                title: `Error`,
                message: `${response.data.errors[0].message}`
              })
            )
          }
        }
      } catch (error) {
        dispatch(
          Notifications.error({
            title: `Error`,
            message: `${error.toString()}`
          })
        )
      }
    }
  },
  /**
   *
   * @param currentSession
   * @param userInfo
   * @returns {function(...[*]=)}
   */
  saveJob() {
    return async (dispatch, getStore) => {
      const { userSession } = getStore()
      if (userSession) {
        await dispatch(
          this.loadSuccess({
            actionType: ActionTypes.SET_USER_SESSION,
            data: userSession
          })
        )

        const clauseSearch = userSessionClause.initQueryCreateOrUpdateUserSession(
          userSession
        )
        dispatch(
          this.createData({
            clause: clauseSearch
          })
        )
      }
    }
  },
  /**
   * create data
   * @param clause
   * @param pathQuery
   * @param pathRedirect
   * @param enableGoBack
   * @returns {Function}
   */
  createData({ clause, userId }) {
    return async (dispatch) => {
      dispatch(startFetchingAction())
      try {
        console.log('create jobs.................')
        const queryData = query[USER_SESSION_PATH].create(clause)
        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })
        if (response.status === 200 && !response.data.errors) {
          //update redux
          const result = response?.data?.data?.saveJob
          if (result) {
            console.log('result...........', result)
            dispatch(redirectPath(`${routes.ROUTE_USER_SESSION}/${result.id}`))
            dispatch(downloadFile(generatePathWithDomain(result.path)))
          }
        } else {
          dispatch(
            Notifications.error({
              title: `Error`,
              message: `${response.data.errors[0].message}`
            })
          )
          dispatch(loadFail(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(
          Notifications.error({
            title: `Error`,
            message: `${error.toString()}`
          })
        )
      }
      dispatch(stopFetchingAction())
    }
  },

  /**
   * update data
   * @param clause
   * @param pathQuery
   * @param pathRedirect
   * @param enableGoBack
   * @returns {Function}
   */
  updateData({ clause, pathQuery, pathRedirect = null, enableGoBack = true }) {
    return async (dispatch) => {
      dispatch(startFetchingAction())
      try {
        console.log('update jobs.................')
        const queryData = query[USER_SESSION_PATH].update(clause)
        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })
        if (response.status === 200 && !response.data.errors) {
          //update redux
          console.log('update success.................')
        } else {
          dispatch(
            Notifications.error({
              title: `Error`,
              message: `${response.data.errors[0].message}`
            })
          )

          dispatch(loadFail(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(
          Notifications.error({
            title: `Error`,
            message: `${error.toString()}`
          })
        )
        dispatch(loadFail(error.response))
      }
      dispatch(stopFetchingAction())
    }
  },

  setActiveTab(tabKey, featureCategoryId) {
    return (dispatch, getStore) => {
      const { data } = getStore()
      const defaultStyles =
        data
          ?.get('featureCategory')
          ?.menuFeatureCategories?.map(({ defaultStyle }) => defaultStyle) ?? []
      dispatch({
        type: ActionTypes.ACTIVE_TAB,
        payload: {
          data: { activeTab: tabKey, featureCategoryId, defaultStyles }
        }
      })
    }
  },

  replaceContentTab(featureCategoryId) {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.REPLACE_CONTENT_TAB,
        payload: {
          isReplace: true,
          isDelete: false,
          featureCategoryId
        }
      })
    }
  },

  deleteContentTab(featureCategoryId) {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.REPLACE_CONTENT_TAB,
        payload: {
          isReplace: false,
          isDelete: true,
          featureCategoryId
        }
      })
    }
  },

  setActiveCategory(categoryId) {
    return (dispatch) =>
      dispatch({
        type: ActionTypes.SET_ACTIVE_CATEGORY,
        payload: { data: categoryId }
      })
  },

  setUserInfo(userInfo) {
    return (dispatch) =>
      dispatch({
        type: ActionTypes.SET_USER_INFO,
        payload: { data: userInfo }
      })
  },

  changeUserInfo(field, value) {
    return (dispatch) =>
      dispatch({
        type: ActionTypes.CHANGE_USER_INFO,
        payload: {
          data: {
            field,
            value
          }
        }
      })
  },

  resetSession() {
    return async (dispatch, getState) => {
      dispatch({
        type: ActionTypes.CLEAR_USER_SESSION
      })

      const { menuFeatureCategories } =
        getState().data?.get(FEATURE_CATEGORY_PATH) ?? {}

      if (menuFeatureCategories?.length > 0) {
        dispatch(
          this.setActiveStyle(false, menuFeatureCategories[0].defaultStyle)
        )
      }
    }
  }
}

export default userSessionActions
