import { stringExtensions } from '../../extensions'

export default {
  loadAllCategories() {
    const whCls = 'parentId: null, isDeleted: false, isPublished: true'
    return `
    query {
      categories(where: { ${whCls} }, order: "order") {
        id
        name
      }
    }    
    `
  },

  loadProductByCategory(categoryId) {
    const whCls = `, categoryId: "${stringExtensions.removeAccentedCharacter(
      categoryId
    )}"`

    return `
    query {
      searchProductByCategory( where: { ${whCls} } ) {
        categoryId
        categoryName
        products {
          id
          name
          images
          categoryId
          properties(
            where: { isDeleted: false, isPublished: true }
            order: "order"
          ) {
            id
            key
            shortDisplayName
            longDisplayName
            description
            defaultValue
            defaultInch
            isShowDefault
            variables
            variablesInches
          }
        }
      }
    }    
    `
  }
}
