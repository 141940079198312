import category from './category'
import product from './product'
import property from './property'
import feature from './feature'
import featureProduct from './featureProduct'
import featureCategory from './featureCategory'
import finishList from './finishList'
import userSession from './useSession'
import selectCategory from './select/category'
export default {
  category,
  product,
  property,
  feature,
  featureProduct,
  featureCategory,
  finishList,
  userSession,
  // SELECT
  selectCategory
}
