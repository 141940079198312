import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ProtectedRoute from '../components/CustomRoute/ProtectedRoute'
import { navHome, routes } from './home'

export const navigation = [...navHome]

const AppRoute = (props) => {
  const generateAdminRoute = (data, index) => {
    if (!data) {
      return null
    }

    if (data.children && data.children.length > 0) {
      return data.children.map((item, index) => generateAdminRoute(item, index))
    }

    if (!data.component) {
      return null
    }

    if (data.isProtected !== false) {
      //console.log('data..........', data)
      return (
        <ProtectedRoute
          {...props}
          exact={data.isExactly}
          key={() => Math.random()}
          path={data.path}
          component={data.component}
          resource={data.resource}
          action={data.actionType}
        />
      )
    }

    return (
      <Route
        {...props}
        key={index}
        path={data.path}
        component={data.component}
        exact={data.isExactly}
      />
    )
  }

  return (
    <Switch>
      {navigation.map((data, index) => generateAdminRoute(data, index))}
      <Redirect to={routes.ROUTE_HOME_PAGE} />
    </Switch>
  )
}

export default AppRoute
