import React from 'react'
import classNames from 'classnames'
import { connectModal } from 'redux-modal'

import Modal from 'antd/lib/modal'

const ModalConfirm = props => {
  const {
    show,
    handleHide,
    title,
    content,
    handleOkClick,
    handleCancelClick,
    okText,
    cancelText,
    className,
    typeModal,
    isShowButtonCancel,
    okStyle
  } = props
  return (
    <Modal
      centered={true}
      visible={show}
      mask={false}
      closable={false}
      cancelText={cancelText ? cancelText : 'No'}
      okText={okText ? okText : 'Yes'}
      className={
        classNames(
          'custom-modal custom-modal-sign-out modal-loan-request',
          className
        )
      }
      autoFocusButton="cancel"
      maskClosable={true}
      cancelButtonProps={{
        style: { order: 1, display: isShowButtonCancel ? 'none' : null  },
        className: `btn-color-grey font-weight-bold`
      }}
      okButtonProps={{
        style: { order: 2, width: okStyle ? '100%' : null },
        className: `btn-color-main font-weight-bold`
      }}
      title={title}
      onCancel={() => {
        if (handleCancelClick) {
          handleCancelClick()
        }
        handleHide()
      }}
      onOk={() => {
        if (handleOkClick) {
          handleOkClick()
        }
        handleHide()
      }}
    >
      <div className={'text-center font-size-14 font-cabin text-center mb-0'}>
        {content}
      </div>
    </Modal>
  )
}

export default connectModal({ name: 'modalConfirm' })(ModalConfirm)
