import { queryStringExtensions, stringExtensions } from '../extensions'
import featureClause from './feature'
export default {
  initQueryFinishLists(values = {}) {
    let query = `isDeleted: false, isPublished: true `
    if (values.product) {
      const productId = stringExtensions.removeAccentedCharacter(values.product)
      query += `, productId: "${productId}"`
    }
    return {
      whereClause: `where: {${query}}`
    }
  }
}
